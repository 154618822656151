import React from "react";
import Spinner from './../Spinner';


const PublicLayout = ({
    loading = false,
    children,
}) => {

    if (loading) {
        return (<Spinner></Spinner>);
    } else {
        return (
            <React.Fragment>
                <main>
                    <div className="">
                        {children}
                    </div>
                </main>
            </React.Fragment>
        );
    }

};

export default PublicLayout;