import React from "react";
import { FormattedMessage } from "react-intl";
import { faCoffee, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="NotFound">
      <h3><FormattedMessage id="notfound-message" defaultMessage="Sorry, page not found"></FormattedMessage></h3>
      <div>
        <FontAwesomeIcon icon={faCoffee} className="fa-10x"></FontAwesomeIcon>
        <p>
          <NavLink to="/"><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> <FormattedMessage id="back"></FormattedMessage></NavLink>
        </p>
      </div>
    </div>
  );
};

export default NotFound;