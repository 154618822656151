import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { detectBrowserlanguage } from './services/language';
import Routes from './routes';
import messages_nl from "./translations/nl.json";
import messages_en from "./translations/en.json";
import { ToastProvider } from 'react-toast-notifications';

const App = () => {

    const [LanguageObject, setLanguageObject] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLanguageObject({
            messages: { en: messages_en, nl: messages_nl },
            language: detectBrowserlanguage()
        }, setLoading(false));
    }, [setLanguageObject, setLoading]);


    return (
        <React.Fragment>
            {loading ? (<p>loading language settings</p>) : (
                <IntlProvider locale={LanguageObject.language} messages={LanguageObject.messages[LanguageObject.language]}>
                    <ToastProvider>
                        <Routes ></Routes>
                    </ToastProvider>
                </IntlProvider>
            )}
        </React.Fragment>
    );
}

export default App;
