import React, { useState, useEffect } from "react";

import { faPowerOff, faSpinner, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import {
    Button,
    Form,
} from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { PublicLayout } from './../../components/layouts';
import { InputMessage } from '../../components/helpers';
import { AccountActivateService, AccountActivationStatus } from '../../services/users';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
import { ReactComponent as FactuurtjeLogo } from './../../assets/images/factuurtje_online_logo_white.svg';

const AccountActivation = ({ ...props }) => {
    const [validationToken, setValidationToken] = useState();
    const [userEmail, setUserEmail] = useState();
    const { register, handleSubmit, errors, formState, watch } = useForm();
    const { touched } = formState;
    const { addToast } = useToasts();
    const history = useHistory();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faPowerOff);

    useEffect(() => {
        // set the sessiondata (if not set, redirect to /login page)
        if (props.match?.params && !validationToken) {
            const val = props.match.params.code;
            setValidationToken(val);

            // get the validation status
            AccountActivationStatus(val)
                .then(response => {
                    if (response.status) {
                        setUserEmail(response.data.user_email);
                    } else {
                        history.push('/login');
                        addToast(response.msg, {
                            appearance: 'error',
                            autoDismiss: true,
                            autoDismissTimeout: 30000,
                        });
                    }
                })
                .catch(error => {
                    console.error('error: ', error);
                    history.push('/login');
                    addToast("Account activation not possible! Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                });
        } else {
            history.push('/login');
        };
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValidationToken, setUserEmail]);

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);

        // set data for services
        const postdata = {
            validation_token: validationToken,
            new_password: data.new_password,
            confirm_new_password: data.confirm_new_password,
            user_email: userEmail,
        }

        AccountActivateService(postdata)
            .then(response => {
                if (response.status) {
                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                    history.push('/login');
                } else {
                    setButtonDisabled(false);
                    setButtonIcon(faPowerOff);
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                };
            })
            .catch(error => {
                console.error('error: ', error);

                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }

                setButtonDisabled(false);
                setButtonIcon(faPowerOff);
            });
    };

    return (
        <PublicLayout>
            <div className="login-container">

                <div className="login">

                    <div className="login-header">
                        {/* <FontAwesomeIcon icon={faMap}></FontAwesomeIcon> Factuurtje Online            */}
                        <FactuurtjeLogo className="form-box-logo" />
                    </div>
                    <form className="login-form">
                        <div className="login-content">

                            <React.Fragment>
                                <p className="text-center"><FormattedMessage id="activate-account-header"></FormattedMessage></p>

                                {/* new password field */}
                                <Form.Group>
                                    <label htmlFor="#newpassword"><FormattedMessage id="newpassword"></FormattedMessage></label>
                                    <FormattedMessage id="newpassword">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                minLength: { value: 8, message: "inputfield-minLength8" },
                                                pattern: {
                                                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                                    message: "inputfield-PasswordPatternNotMatch",
                                                },
                                            })} type="password" id="#newpassword"
                                                data-testid="new_password"
                                                name="new_password"
                                                placeholder={NameField}
                                                isValid={(touched.new_password && !errors.new_password) ? true : false}
                                                isInvalid={errors.new_password ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.new_password?.message}></InputMessage>
                                </Form.Group>

                                {/* new password field */}
                                <Form.Group>
                                    <label htmlFor="#repeatpassword"><FormattedMessage id="repeatpassword"></FormattedMessage></label>
                                    <FormattedMessage id="repeatpassword">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                minLength: { value: 8, message: "inputfield-minLength8" },
                                                validate: {
                                                    compare: value => {
                                                        if (watch('new_password') !== value) {
                                                            return 'PasswordsNotEqual';
                                                        } else {
                                                            return undefined;
                                                        };
                                                    },
                                                }
                                            })} type="password"
                                                id="#repeatpassword"
                                                data-testid="confirm_new_password"
                                                name="confirm_new_password"
                                                placeholder={NameField}
                                                isValid={(touched.confirm_new_password && !errors.confirm_new_password) ? true : false}
                                                isInvalid={errors.confirm_new_password ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.confirm_new_password?.message}></InputMessage>
                                </Form.Group>

                                {/* reset password button */}
                                <Form.Group className="text-center">
                                    <Button
                                        data-testid="reset_button"
                                        disabled={buttonDisabled} block variant="outline-dark"
                                        onClick={handleSubmit(onSubmit)}>
                                        <FormattedMessage id="activate-account"></FormattedMessage> <FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''}></FontAwesomeIcon>
                                    </Button>
                                </Form.Group>
                            </React.Fragment>

                            <Form.Group className="">
                                <p>
                                    <Link to="/login"><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> <FormattedMessage id="back"></FormattedMessage></Link>
                                </p>
                            </Form.Group>
                        </div>
                    </form>
                </div>
            </div>

        </PublicLayout >
    );
};

export default AccountActivation;