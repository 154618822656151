import React, { useState, useEffect, useRef } from "react";

import {
    Button, Form, ButtonGroup,
    ButtonToolbar, InputGroup
} from "react-bootstrap";
import {
    faAngleLeft, faAngleRight, faUserPlus, faUserTie,
    faTrashAlt, faSearch, faArrowAltCircleRight, faUser
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import Moment from 'react-moment';
import debounce from 'lodash.debounce';

import { ProtectedLayout } from './../../components/layouts';
import Spinner from "../../components/Spinner";
import { ListCustomers, SearchCustomers } from '../../services/customers';
import { StatusBadge } from '../../components/helpers';
import AddCustomerModal from './modals/customer.add.modal';

import './style.css';

export default function CustomersGridview({
    history,
    match,
    ...props
}) {
    const [tableLoading, setTableLoading] = useState(true);
    const [customerData, setCustomerData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [addCustomerOpen, setAddCustomerOpen] = useState(false);
    const { addToast } = useToasts();
    const pagestatus = match.params.mode

    useEffect(() => {

        Loadcustomers();

        // if mode is new, open the add customer popup
        if (pagestatus === 'new') {
            setAddCustomerOpen(true);
        }

        return () => {
            // cleanup
            setCustomerData([]);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAddCustomerOpen, setCustomerData, pagestatus])


    const Loadcustomers = () => {
        setTableLoading(true);
        setCustomerData([]);

        ListCustomers()
            .then(response => {
                setCustomerData(response.data.customers_list);
                setTableLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });

    };

    const OpenCustomer = (customer_id) => {
        history.push('/customer/' + customer_id);
    };


    // first function for interact on the search input
    const handleSearchInput = (event) => {
        const data = {
            'search_value': event.target.value,
            'search_type': 'customer',
        };
        setSearchInput(event.target.value);
        handleSearchBarDebounced.current(data);
    };


    // second function, called from the search bar handler
    const handleSearchBarDebounced = useRef(
        debounce(searchInput => {
            // if input field is empty or les the 2 chars, load the total list
            if (searchInput.search_value.length === 0) {
                Loadcustomers();
            } else {
                handleSearchBar(searchInput);
            }

        }, 500)
    );

    // search bar handler
    const handleSearchBar = (p_SearchInput) => {
        setTableLoading(true);
        setCustomerData([]);
        setSearchInput(p_SearchInput.search_value);

        SearchCustomers(p_SearchInput)
            .then(response => {
                if (response.status) {
                    setCustomerData(response.data.customers_list);
                } else {
                    addToast(response.msg, {
                        appearance: 'warning',
                        autoDismiss: true,
                        // autoDismissTimeout: 30000,
                    });
                }

                setTableLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };


    return (
        <ProtectedLayout {...props} history={history}>
            <div className="row">
                <div className="col-12 mb-5">
                    <h5>
                        <FormattedMessage id="customer-list-page-header" />
                    </h5>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-5">
                    <div className="main-page-container main-page-container--background">
                        <div className="btn-toolbar-container">
                            {/* Header table toolbar */}
                            <ButtonToolbar>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormattedMessage id="search-companyNameID">
                                        {NameField =>
                                            <Form.Control placeholder={NameField}
                                                autoFocus={true}
                                                onChange={event => { handleSearchInput(event) }}
                                                value={searchInput}
                                            />
                                        }</FormattedMessage>
                                    <InputGroup.Append>
                                        <Button onClick={e => { setSearchInput(''); Loadcustomers() }} disabled={searchInput.length > 0 ? false : true} variant="outline-secondary"><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></Button>
                                    </InputGroup.Append>
                                </InputGroup>

                                {/*
                            <InputGroup>
                                <Form.Control placeholder="Search..."
                                    autoFocus={true}
                                    onChange={event => { handleSearchInput(event) }}
                                    value={searchInput}
                                />
                            </InputGroup> */}

                                <ButtonGroup className="ml-auto">
                                    {/* <Button variant="outline-secondary">Iets</Button> */}
                                    <Button variant="outline-secondary" onClick={() => { setAddCustomerOpen(!addCustomerOpen) }}><FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon> <FormattedMessage id="customers-AddNewCustomer"></FormattedMessage></Button>
                                    {/* <Button variant="outline-danger">Delete</Button> */}
                                </ButtonGroup>
                            </ButtonToolbar>
                        </div>

                        {/* Data table */}
                        <div className="table-responsive-xl">
                            {tableLoading ? (<Spinner label="Loading customer data..."></Spinner>) : (
                                <table className="table table-hover table-cursor-enabled">
                                    <thead className="table-header">
                                        <tr>
                                            <th width="5%">#</th>
                                            <th width="12%" className="text-center"><FormattedMessage id="customer-type"></FormattedMessage></th>
                                            <th><FormattedMessage id="customer-name"></FormattedMessage></th>
                                            <th><FormattedMessage id="contact_name"></FormattedMessage></th>
                                            <th className="text-center"><FormattedMessage id="customer_status"></FormattedMessage></th>
                                            <th width="12%"><FormattedMessage id="create_date"></FormattedMessage></th>
                                            <th width="5%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerData.map((row) => (
                                            <tr key={row.customer_id} onClick={() => OpenCustomer(row.customer_id)}>
                                                <th className="align-middle">{row.customer_debtor_number}</th>
                                                <td className="text-center align-middle"><span className={`badge ${row.customer_data.business_account ? 'badge-secondary' : 'badge-info'}`}>
                                                    {row.customer_data.business_account ?
                                                        (<span><FontAwesomeIcon icon={faUserTie} /> <FormattedMessage id="business" /></span>)
                                                        :
                                                        (<span><FontAwesomeIcon icon={faUser} /> <FormattedMessage id="private" /></span>)}
                                                </span></td>
                                                <td className="align-middle">
                                                    <div>{row.customer_name}</div>
                                                    <small className="small">{row.customer_data.customer_address.customer_street} - {row.customer_data.customer_address.customer_city}</small>
                                                </td>
                                                <td className="align-middle">
                                                    <div>{row.customer_data.contact_name > 1 ? row.customer_data.contact_name : row.customer_name}</div>
                                                    <small className="small">{row.customer_data.contact_email}</small>
                                                </td>
                                                <td className="text-center align-middle"><StatusBadge status={row.customer_status} /> </td>
                                                <td className="align-middle"><Moment format="DD-MM-YYYY" date={row.customer_created} /></td>
                                                <td className="align-middle"><Button onClick={() => OpenCustomer(row.customer_id)} size="sm" variant="outline-secondary"><FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon></Button></td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>

                        {customerData.length > 0 ? (
                            <div className="btn-toolbar-container">
                                <ButtonToolbar>
                                    <ButtonGroup className="ml-auto" size="sm">
                                        <Button variant="outline-secondary"><FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon></Button>
                                        <Button variant="outline-secondary"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </div>
                        ) : (
                                tableLoading ? (null) : (<div className="p-2">Geen klanten gevonden</div>)
                            )
                        }
                    </div>
                </div>
            </div>

            <AddCustomerModal modalIsOpen={addCustomerOpen} modalOpenerHandler={setAddCustomerOpen}></AddCustomerModal>

        </ProtectedLayout>
    );
};
