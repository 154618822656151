import React from "react";
import { Button, Modal } from "react-bootstrap";

import { faTimes, faTimesCircle, faArrowRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

const WarningInvoiceVATModal = ({
    modalIsOpen,
    modalOpenerHandler,
    customerID,
    history,
}) => {

    return (

        <Modal size="lg" show={modalIsOpen} onHide={() => { modalOpenerHandler(!modalIsOpen) }}>

            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon className="text-danger" icon={faTimesCircle}></FontAwesomeIcon> <FormattedMessage id="customer-missing-vat-header"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-warning">
                <div className="p-3 mb-2">
                    <p><FormattedMessage id="customer-missing-vat"></FormattedMessage></p>
                    <p className="text-center">
                        <div onClick={() => { history.push(`/customer/${customerID}`); modalOpenerHandler(false); }} className="btn btn-lg btn-secondary"><FormattedMessage id="customers-EditCustomer" /> <FontAwesomeIcon icon={faArrowRight} /></div>
                    </p>
                    <p><small className="text-secondary small"><FontAwesomeIcon icon={faInfoCircle} /> De factuur is opgeslagen. Klik op factuur toevoegen voor deze klant om de factuur alsnog kan verzenden</small></p>

                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => { modalOpenerHandler(!modalIsOpen) }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> <FormattedMessage id="close"></FormattedMessage></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WarningInvoiceVATModal;