import React from "react";
import { useFetchAuth } from './../../services/authentication/auth';
import HeaderBar from '../header';
import Spinner from './../Spinner';

const ProtectedLayout = ({
    children,
    ...props
}) => {
    const { auth, loading } = useFetchAuth({ required: true });

    return (
        <React.Fragment>
            <HeaderBar auth={auth} {...props}></HeaderBar>
            <main>
                <div className="container mt-4">
                    {loading ? (<Spinner></Spinner>) : (children)}
                </div>
            </main>

        </React.Fragment>
    );
};

export default ProtectedLayout;
