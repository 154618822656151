import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Badge } from "react-bootstrap";

const StatusBadge = ({
    status,
    id
}) => {
    // primary, secondary, success, danger, warning, info, light, dark
    const [theme, setTheme] = useState();

    useEffect(() => {
        switch (status) {
            case "OPEN":
                setTheme("primary");
                break;
            case "DRAFT":
                setTheme("warning");
                break;
            case "OVER_DUE":
            case "ERROR_CREATING":
                setTheme("danger");
                break;
            case "ACTIVE_BILLING_ONLY":
            case "PAYED":
            case "ACTIVE":
                setTheme("success");
                break;
            case "NUMBER_NOT_SET":
            case "PROCESSING":
                setTheme("info");
                break;

            default:
                setTheme("primary");
                break;
        }
        return () => {
            // cleanup
        }
    }, [status])

    return (
        <Badge variant={theme} id={id}>
            <FormattedMessage id={status}></FormattedMessage>
        </Badge>
    );
};

export default StatusBadge;