import React, { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useToasts, } from 'react-toast-notifications';

import { ProtectedLayout, } from './../../components/layouts';
import { CustomerSettings, CustomerDetails, } from './../../services/customers';
import { InvoiceDetails, } from './../../services/invoices';
import { DeleteDraftInvoiceModal, SaveInvoiceModal } from './modals';
import { InvoiceTemplate, } from './index';
import { useForm, useWatch, } from "react-hook-form";
import Spinner from "../../components/Spinner";

const InvoiceEditPage = ({
    history,
    match,
    ...props
}) => {

    const [customerSettings, setCustomerSettings] = useState();
    const [invoiceData, setInvoiceData] = useState({ invoice_data: {} });
    const [invoiceDetails, setInvoiceDetailsLoading] = useState(true);
    const [customerSettingsDataLoading, setCustomerSettingsDataLoading] = useState(true);
    const [invoiceDataLoading, setInvoiceDataLoading] = useState(true);
    const [openDeleteDraftInvoice, setOpenDeleteDraftInvoice] = useState(false);
    const [openSaveInvoice, setOpenSaveInvoice] = useState(false);

    const [invoiceID, setInvoiceID] = useState(undefined);
    const { addToast } = useToasts();
    const { register, control, reset, watch } = useForm({
        defaultValues: { invoiceLines: [{ quantity: '1', description: '', amount: '', vat_total: '0' }] }
    });
    const invoiceLines = useWatch({ name: "invoiceLines", control });

    // effect on checking if all data has loaded
    useEffect(() => {
        if (!customerSettingsDataLoading && !invoiceDetails) {
            setInvoiceDataLoading(false);
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDetails, customerSettingsDataLoading])


    // effect on loading customerSettings data (initial rendering)
    useEffect(() => {
        if (match.params.invoice_id) {
            setInvoiceID(match.params.invoice_id);
            LoadingSettings();
            LoadInvoiceData(match.params.invoice_id);
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match]);

    // effect on loading the invoicelines
    useEffect(() => {
        if (!invoiceDataLoading && invoiceData?.invoice_lines) {
            reset({ invoiceLines: invoiceData.invoice_lines });
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDataLoading]);

    const LoadingSettings = () => {
        CustomerSettings("me")
            .then(response => {
                if (response.status) {
                    setCustomerSettings(response.data);
                    setCustomerSettingsDataLoading(false);
                } else {
                    addToast("Error on requesting settings data.", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    const LoadInvoiceData = (p_InvoiceID) => {
        setInvoiceDataLoading(true);
        InvoiceDetails(p_InvoiceID)
            .then(response => {
                if (response.status) {

                    // getting new customer data
                    CustomerDetails(response.data.customer_id)
                        .then(customer_response => {
                            if (customer_response.status) {

                                setInvoiceData({
                                    customer_id: response.data.customer_id,
                                    invoice_number: response.data.invoice_number,
                                    invoice_id: response.data.invoice_id,
                                    invoice_date: response.data.invoice_date,
                                    invoice_data: {
                                        customer_data: {
                                            customer_name: customer_response.data.customer_name,
                                            contact_name: customer_response.data.customer_data.contact_name,
                                            contact_email: customer_response.data.customer_data.contact_email,
                                            customer_vat: customer_response.data.customer_data.customer_vat,
                                            customer_debtor_number: customer_response.data.customer_debtor_number.toString(),
                                            business_account: customer_response.data.customer_data.business_account,
                                            ...customer_response.data.customer_data.customer_address,
                                        },
                                    },
                                    invoice_lines: [...response.data.invoice_lines],
                                    finance_data: { ...response.data.finance_data },
                                }, setInvoiceDetailsLoading(false));
                            }
                        });

                } else {
                    history.push('/invoices');
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };


    if (invoiceDataLoading) { return <Spinner /> }

    return (
        <ProtectedLayout {...props} history={history}>
            <div className="row">
                <div className="col-12 mb-5">
                    <h5>
                        <FormattedMessage id="invoice-edit-page-header" />: {invoiceData.invoice_data.customer_data.customer_name}
                    </h5>
                </div>
            </div>

            <InvoiceTemplate
                saveInvoice={setOpenSaveInvoice}
                setOpenDeleteDraftInvoice={setOpenDeleteDraftInvoice}
                invoiceDataLoading={invoiceDataLoading}
                invoiceData={invoiceData}
                customerSettings={customerSettings}
                setInvoiceData={setInvoiceData}
                register={register}
                control={control}
                watch={watch}
                invoiceLines={invoiceLines}
            />

            {!invoiceDataLoading && (
                <DeleteDraftInvoiceModal
                    customerData={invoiceData.invoice_data.customer_data}
                    modalIsOpen={openDeleteDraftInvoice}
                    modalOpenerHandler={setOpenDeleteDraftInvoice}
                    invoiceID={invoiceID}
                    history={history}
                />
            )}

            {!invoiceDataLoading && (
                <SaveInvoiceModal
                    invoiceData={invoiceData}
                    modalIsOpen={openSaveInvoice}
                    modalOpenerHandler={setOpenSaveInvoice}
                    history={history}
                />
            )}

        </ProtectedLayout>
    );
};

export default InvoiceEditPage;