import React, { useState } from "react";

import { faPaperPlane, faSpinner, faArrowLeft, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import {
    Form,
    Button,
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { PublicLayout } from './../../components/layouts';
import { InputMessage } from '../../components/helpers';
import { RequestPassword } from '../../services/users';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
import { ReactComponent as FactuurtjeLogo } from './../../assets/images/factuurtje_online_logo_white.svg';

const RequestPasswordPage = ({ ...props }) => {

    const { register, handleSubmit, errors, formState, setValue } = useForm();
    const { touched } = formState;
    const { addToast } = useToasts();
    const history = useHistory();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faPaperPlane);
    // const [mailaddress, setMailaddress] = useState();

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);

        RequestPassword(data)
            .then(response => {
                if (response.status) {
                    setButtonDisabled(false);
                    setButtonIcon(faCheckSquare);    // set the button icon to the next step
                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                    history.push('/login');
                } else {
                    setButtonDisabled(false);
                    setButtonIcon(faPaperPlane);
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                };
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
                setButtonDisabled(false);
                setButtonIcon(faPaperPlane);
            });
    };


    return (
        <PublicLayout>
            <div className="login-container">

                <div className="login">

                    <div className="login-header">
                        {/* <FontAwesomeIcon icon={faMap}></FontAwesomeIcon> Factuurtje Online */}
                        <FactuurtjeLogo className="form-box-logo" />
                    </div>
                    <form className="login-form">
                        <div className="login-content">

                            <React.Fragment>
                                <p className="text-center"><FormattedMessage id="request-password-header"></FormattedMessage></p>
                                {/* mail address field */}
                                <Form.Group>
                                    <label htmlFor="#username"><FormattedMessage id="your-emailaddress"></FormattedMessage></label>
                                    <FormattedMessage id="your-emailaddress">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                                    message: "EmailAddressNotValid",
                                                },
                                            })} id="#username" name="user_email" placeholder={NameField}
                                                onChange={e => { setValue("user_email", e.target.value.toLowerCase()) }}
                                                isValid={(touched.user_email && !errors.user_email) ? true : false}
                                                isInvalid={errors.user_email ? true : false}
                                                data-testid="user_email" />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.user_email?.message}></InputMessage>
                                </Form.Group>

                                {/* request code button */}
                                <Form.Group className="text-center">
                                    <Button disabled={buttonDisabled} block variant="outline-dark"
                                        data-testid="request_button" onClick={handleSubmit(onSubmit)}>
                                        <FormattedMessage id="request-password"></FormattedMessage> <FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''}></FontAwesomeIcon>
                                    </Button>
                                </Form.Group>
                            </React.Fragment>


                            <Form.Group className="">
                                <p>
                                    <Link to="/login"><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> <FormattedMessage id="back"></FormattedMessage></Link>
                                </p>
                            </Form.Group>
                        </div>
                    </form>
                </div>
            </div>

        </PublicLayout >
    );
};

export default RequestPasswordPage;