import React, { useState, useEffect } from "react";

import {
    Button,
    Modal,
    Form
} from "react-bootstrap";

import { faTimes, faSpinner, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { InvoiceSend } from './../../../services/invoices';
import { useForm } from "react-hook-form";
import { InputMessage } from '../../../components/helpers';
import { useHistory } from "react-router-dom";


const SendInvoiceModal = ({
    modalIsOpen,
    modalOpenerHandler,
    invoiceData,
    refreshInvoice,
    customerSettings,
}) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faPaperPlane);
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, formState, setValue, } = useForm();
    const { touched } = formState;
    const history = useHistory();

    useEffect(() => {
        if (invoiceData.invoice_data.customer_data.contact_email) {
            setValue("contact_email", invoiceData.invoice_data.customer_data.contact_email);
        } else {
            setValue("contact_email", invoiceData.current_customer_data.customer_data.contact_email);
        }

        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceData])

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);

        // get the auth info from localstore
        var localstore_data = JSON.parse(localStorage.getItem("authentication"));

        const post_data = {
            invoice_id: invoiceData.invoice_id,
            customer_id: invoiceData.customer_id,
            contact_email: data.contact_email,
            contact_name: invoiceData.invoice_data.customer_data.contact_name,
            invoice_number: invoiceData.invoice_number,
            send_copy: data.send_copy,
            send_accountancy: data.send_accountancy,
            user_email: localstore_data['user_email'],
            save_mailaddress: data.save_mailaddress,
        };

        InvoiceSend(post_data)
            .then(response => {
                if (response.status) {
                    modalOpenerHandler(false);
                    setButtonDisabled(false);
                    setButtonIcon(faPaperPlane);
                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    if (data.save_mailaddress) { refreshInvoice(); };
                } else {
                    setButtonDisabled(false);
                    setButtonIcon(faPaperPlane);
                };
            })
            .catch(error => {
                console.error("Error sending invoice: ", error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
                setButtonDisabled(false);
                setButtonIcon(faPaperPlane);
            });
    };


    return (

        <Modal size="lg" show={modalIsOpen} onHide={() => { modalOpenerHandler(false) }}>

            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon> <FormattedMessage id="invoice-send"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><FormattedMessage id="invoice-send-Header"></FormattedMessage></p>
                <div className="p-3 mb-2">

                    <div className="form-group row">
                        <label htmlFor="emailaddress" className="col-sm-6 col-form-label"><FormattedMessage id="invoice-send-to"></FormattedMessage>:</label>
                        <div className="col-sm-6">

                            <FormattedMessage id="emailaddress">
                                {NameField =>
                                    <Form.Control ref={register({
                                        required: { value: true, message: "inputfield-FieldIsRequired" },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                            message: "EmailAddressNotValid",
                                        },
                                    })} type="text" id="#emailaddress"
                                        data-testid="contact_email"
                                        name="contact_email"
                                        placeholder={NameField}
                                        onChange={e => { setValue("contact_email", e.target.value.toLowerCase()) }}
                                        isValid={(touched.contact_email && !errors.contact_email) ? true : false}
                                        isInvalid={errors.contact_email ? true : false} />
                                }
                            </FormattedMessage>
                            <InputMessage className="text-danger" fade={true} message={errors.contact_email?.message}></InputMessage>
                        </div>
                    </div>

                    {/* save email address checkbox */}
                    <div className="form-group row align-items-center">
                        <label htmlFor="send_copy" className="col-sm-6 col-form-label"><FormattedMessage id="invoice-send-save-emailaddress"></FormattedMessage>:</label>
                        <div className="col-sm-6">
                            <label className="custom-control custom-checkbox">
                                <input
                                    ref={register()}
                                    name="save_mailaddress"
                                    type="checkbox"
                                    className="custom-control-input" />
                                <label className="custom-control-label"></label>
                                <span className="custom-control-description"></span>
                            </label>
                        </div>
                    </div>

                    {/* send copy of invoice */}
                    <div className="form-group row align-items-center">
                        <label htmlFor="send_copy" className="col-sm-6 col-form-label"><FormattedMessage id="invoice-send-me-copy"></FormattedMessage>:</label>
                        <div className="col-sm-6">
                            <label className="custom-control custom-checkbox">
                                <input
                                    ref={register()}
                                    name="send_copy"
                                    type="checkbox"
                                    className="custom-control-input" />
                                <label className="custom-control-label"></label>
                                <span className="custom-control-description"></span>
                            </label>
                        </div>
                    </div>

                    {/* send copy of invoice to accountancy */}
                    <div className="form-group row align-items-center">
                        <label htmlFor="send_accountancy" className="col-sm-6 col-form-label"><FormattedMessage id="invoice-send-accountacy"></FormattedMessage>:</label>
                        <div className="col-sm-6">
                            <label className="custom-control custom-checkbox">
                                <input
                                    ref={register()}
                                    name="send_accountancy"
                                    type="checkbox"
                                    className="custom-control-input" />
                                <span className="small"> {customerSettings?.settings_data.mail_settings.mail_accountancy_address}</span>
                                <label className="custom-control-label"></label>
                                <span className="custom-control-description"></span>
                            </label>
                        </div>
                    </div>


                </div>

            </Modal.Body>


            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => { modalOpenerHandler(false) }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> <FormattedMessage id="close"></FormattedMessage></Button>
                <Button variant="outline-success" disabled={buttonDisabled} onClick={handleSubmit(onSubmit)}><FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''} /> <FormattedMessage id="invoice-send"></FormattedMessage></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SendInvoiceModal;