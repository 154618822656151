export const SERVER = process.env.REACT_APP_SERVER;

// export const AWS_CONFIG = {
//     Auth: {
//         identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL,
//         identityPoolRegion: process.env.REACT_APP_AWS_REGION,
//         region: process.env.REACT_APP_AWS_REGION,
//         userPoolId: process.env.REACT_APP_COGNITO_IDENTITY_USER_POOL_ID,
//         userPoolWebClientId: process.env.REACT_APP_COGNITO_IDENTITY_USER_POOL_CLIENT_ID,
//     },
//     Storage: {
//         bucket: process.env.REACT_APP_PUBLIC_UPLOADS_S3_BUCKET,
//         region: process.env.REACT_APP_AWS_REGION,
//     },
// };