import React from "react";
import { FormattedMessage } from "react-intl";

const InputMessage = ({
    fade = false,
    message,
    className,
}) => {

    return (
        <React.Fragment>
            {message && (
                // <Fade in={fade} className={className}>
                <span className={className}>
                    <FormattedMessage id={message}></FormattedMessage>
                </span>
                // </Fade>
            )}
        </React.Fragment>
    );
};

export default InputMessage;