import * as api from '../api';

export const ListCustomers = () => {
    return new Promise((resolve, reject) => {
        api
            .get('/customer/list')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};

export const CustomerDetails = (customer_id) => {
    return new Promise((resolve, reject) => {
        api
            .get('/customer/details/' + customer_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CustomerSettings = (customer_id) => {
    return new Promise((resolve, reject) => {
        api
            .get('/customer/settings/' + customer_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CustomerSettingsUpdate = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/customer/settings/update', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CustomerFileUpload = (data, file_type) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", data);
        formData.append("file_type", file_type);

        api
            .post('/customer/uploadfile', formData,
                {
                    headers: { "Content-Type": "multipart/form-data", },
                }
            )
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CustomerAdd = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/customer/add', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const SearchCustomers = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/customer/search', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CustomerUpdate = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/customer/update', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CustomerDelete = (data) => {
    return new Promise((resolve, reject) => {
        api
            .remove('/customer/delete', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CustomerDeleteFile = (data) => {
    return new Promise((resolve, reject) => {
        api
            .remove('/customer/deletefile', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};