import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Spinner from "../../../components/Spinner";
import { StatusBadge } from '../../../components/helpers';
import { faUserTie, faUser, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomerDetailView = ({
    customerdata,
    dataloading,
    LoadCustomerData,
    history,
}) => {

    const [hideProfileFields, setHideProfileFields] = useState(true);

    useEffect(() => {
        if (history.location.pathname === '/profile') {
            setHideProfileFields(true);
        } else {
            setHideProfileFields(false);
        }
    }, [history])

    return (
        <React.Fragment>
            {dataloading ? (<Spinner></Spinner>) : (
                <React.Fragment>
                    <div className="customer-detail-view">

                        <form>

                            {/* customer basic card */}
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Details"></FormattedMessage></h5>

                                    {/* customer_debtor_number */}
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabel" className="col-sm-4 col-form-label"><FormattedMessage id="customer-debtornumber"></FormattedMessage></label>
                                        <div className="col-sm-8">
                                            #{customerdata.customer_debtor_number}
                                        </div>
                                    </div>

                                    {/* customer_name */}
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabel" className="col-sm-4 col-form-label"><FormattedMessage id={customerdata.customer_data.business_account ? `company-name` : `customer-name`}></FormattedMessage></label>
                                        <div className="col-sm-8">
                                            <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_name} />
                                        </div>
                                    </div>

                                    {/* customer type */}
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabel" className="col-sm-4 col-form-label"><FormattedMessage id="customer-type"></FormattedMessage></label>
                                        <div className="col-sm-8">
                                            <span className={`badge ${customerdata.customer_data.business_account ? 'badge-secondary' : 'badge-info'}`}>
                                                {customerdata.customer_data.business_account ?
                                                    (<span><FontAwesomeIcon icon={faUserTie} /> <FormattedMessage id="business" /></span>)
                                                    :
                                                    (<span><FontAwesomeIcon icon={faUser} /> <FormattedMessage id="private" /></span>)}
                                            </span>
                                        </div>
                                    </div>

                                    {/* customer status */}
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabel" className="col-sm-4 col-form-label"><FormattedMessage id="customer_status"></FormattedMessage></label>
                                        <div className="col-sm-8">
                                            <StatusBadge status={customerdata.customer_status} />
                                        </div>
                                    </div>

                                    {/* Contact person */}
                                    {customerdata.customer_data.business_account && (
                                        <React.Fragment>
                                            <div className="form-group row">
                                                <label htmlFor="colFormLabel" className="col-sm-4 col-form-label"><FormattedMessage id="contact_name"></FormattedMessage></label>
                                                <div className="col-sm-8">
                                                    <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data.contact_name} />
                                                </div>
                                            </div>


                                            {/* company registration number */}
                                            <div className="form-group row">
                                                <label htmlFor="colFormLabel" className="col-sm-4 col-form-label"><FormattedMessage id="company-registration-number"></FormattedMessage></label>
                                                <div className="col-sm-8">
                                                    <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data.customer_reg_number} />
                                                </div>
                                            </div>

                                            {/* company vat number */}
                                            <div className="form-group row">
                                                <label htmlFor="colFormLabel" className="col-sm-4 col-form-label"><FormattedMessage id="company-vat-number"></FormattedMessage></label>
                                                <div className="col-sm-8">
                                                    <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data.customer_vat} />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {/* eligible promotional gift */}
                                    {!hideProfileFields && (
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label"><FormattedMessage id="customer-eligible_promotional_gift"></FormattedMessage></label>
                                            <div className="col-sm-8">
                                                {customerdata.customer_data.eligible_promotional_gift ? (
                                                    <FontAwesomeIcon className="icon-success" icon={faCheckCircle} />
                                                ) : (
                                                        <FontAwesomeIcon className="icon-danger" icon={faTimesCircle} />
                                                    )}
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>

                            {/* Customer address card */}
                            <div className="card mt-4">
                                <div className="card-body">
                                    <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Address"></FormattedMessage></h5>

                                    {/* Adres */}
                                    <div className="form-group row">
                                        <label htmlFor="streetAndNumber" className="col-sm-4 col-form-label"><FormattedMessage id="streetAndNumber"></FormattedMessage></label>
                                        <div className="col-sm-8">
                                            <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data?.customer_address?.customer_street} />
                                        </div>
                                    </div>

                                    {/* zipcode and city form */}
                                    <div className="form-group row">
                                        <label htmlFor="zipcode" className="col-sm-4 col-form-label"><FormattedMessage id="zipcodeAndCitty"></FormattedMessage></label>

                                        {/* zipcode form */}
                                        <div className="col-sm-3">
                                            <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data?.customer_address?.customer_zipcode} />
                                        </div>

                                        {/* ciyt form */}
                                        <div className="col-sm-5">
                                            <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data?.customer_address?.customer_city} />
                                        </div>

                                    </div>

                                    {/* Country */}
                                    <div className="form-group row">
                                        <label htmlFor="country" className="col-sm-4 col-form-label"><FormattedMessage id="country"></FormattedMessage></label>
                                        <div className="col-sm-8">
                                            <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data?.customer_address?.customer_country} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* customer contact card */}
                            <div className="card mt-4">
                                <div className="card-body">
                                    <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Contact"></FormattedMessage></h5>

                                    {/* phone number */}
                                    <div className="form-group row">
                                        <label htmlFor="phonenumber" className="col-sm-4 col-form-label"><FormattedMessage id="contact-phonenumber"></FormattedMessage></label>
                                        <div className="col-sm-8">
                                            <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data.contact_phonenumber} />
                                        </div>
                                    </div>

                                    {/* contact mail address */}
                                    <div className="form-group row">
                                        <label htmlFor="emailaddress" className="col-sm-4 col-form-label"><FormattedMessage id="contact_emailaddress"></FormattedMessage></label>
                                        <div className="col-sm-8">
                                            <input type="text" readOnly className="form-control-plaintext" value={customerdata.customer_data.contact_email} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* customer additional detail card */}
                            {!hideProfileFields && (
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Notes"></FormattedMessage></h5>

                                        <code>{customerdata.customer_data.customer_note}</code>
                                    </div>
                                </div>
                            )}

                        </form>
                    </div>


                </React.Fragment>
            )}


        </React.Fragment>
    );

};


export default CustomerDetailView;