import React, { useState } from "react";

import { faSignInAlt, faSpinner, faEdit, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import {
    Form,
    Button,
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { PublicLayout } from './../../components/layouts';
import { InputMessage } from '../../components/helpers';
import { UserLogin } from '../../services/users';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
import { ReactComponent as FactuurtjeLogo } from './../../assets/images/factuurtje_online_logo_white.svg';

const Login = ({ ...props }) => {

    const { register, handleSubmit, errors, formState, getValues, setValue } = useForm(
        { mode: "onBlur" }, // set the field validation mode
    );
    const { touched } = formState;
    const { addToast } = useToasts();
    const history = useHistory();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faSignInAlt);

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);

        UserLogin(data)
            .then(response => {
                if (response.status) {
                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                    localStorage.setItem('authentication', JSON.stringify(response.data));
                    history.push('/');
                } else {
                    // check if the password needs to changed
                    if (response.data?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                        addToast(response.msg, {
                            appearance: 'warning',
                            autoDismiss: true,
                        });
                        history.push({
                            pathname: '/change-password',
                            state: { sessiondata: response.data },
                        });
                    } else {
                        setButtonDisabled(false);
                        setButtonIcon(faSignInAlt);
                        addToast(response.msg, {
                            appearance: 'error',
                            autoDismiss: true,
                        });
                    }
                };
            })
            .catch(error => {
                console.error('error UserStatus: ', error);
                setButtonDisabled(false);
                setButtonIcon(faSignInAlt);
                addToast("Error connecting to authentication server.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            })
    };

    // handler for pressing the enter key
    const pressEnterKey = (event) => {
        const values = getValues();
        var enterkey = event.keyCode || event.which;
        // check if enter button has pressed and the form is valid
        if (enterkey === 13 && formState.isValid) {
            onSubmit(values);
        };
    };

    return (
        <PublicLayout>
            <div className="login-container">

                <div className="login">

                    <div className="login-header">
                        {/* <FontAwesomeIcon icon={faMap}></FontAwesomeIcon> Factuurtje Online
                         */}
                        <FactuurtjeLogo className="form-box-logo" />
                    </div>
                    <form className="login-form">
                        <div className="login-content">
                            <p className="text-center"><FormattedMessage id="login_message" defaultMessage="Niet gelukt"></FormattedMessage></p>

                            <Form.Group>
                                <label htmlFor="#username"><FormattedMessage id="your-emailaddress"></FormattedMessage></label>
                                <FormattedMessage id="your-emailaddress">
                                    {NameField =>
                                        <Form.Control ref={register({
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                                message: "EmailAddressNotValid",
                                            }
                                        })} id="#username"
                                            name="user_email"
                                            data-testid="user_email"
                                            placeholder={NameField}
                                            onChange={e => { setValue("user_email", e.target.value.toLowerCase()) }}
                                            isValid={(touched.user_email && !errors.user_email) ? true : false}
                                            isInvalid={errors.user_email ? true : false} />
                                    }
                                </FormattedMessage>
                                <InputMessage className="text-danger" fade={true} message={errors.user_email?.message}></InputMessage>
                            </Form.Group>

                            <Form.Group>
                                <label htmlFor="#user_password"><FormattedMessage id="password"></FormattedMessage></label>
                                <FormattedMessage id="password">
                                    {NameField =>
                                        <Form.Control
                                            ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                minLength: { value: 8, message: "inputfield-minLength8" }
                                            })}
                                            type="password"
                                            id="#user_password"
                                            name="user_password"
                                            data-testid="user_password"
                                            autoComplete="off"
                                            placeholder={NameField}
                                            onKeyPress={pressEnterKey}
                                            isValid={(touched.user_password && !errors.user_password) ? true : false}
                                            isInvalid={errors.user_password ? true : false} />
                                    }
                                </FormattedMessage>
                                <InputMessage className="text-danger" fade={true} message={errors.user_password?.message}></InputMessage>
                            </Form.Group>

                            <Form.Group className="text-center">
                                <Button disabled={buttonDisabled}
                                    variant="outline-dark"
                                    block
                                    data-testid="submit_button"
                                    onClick={handleSubmit(onSubmit)}>
                                    <FormattedMessage id="login"></FormattedMessage> <FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''}></FontAwesomeIcon>
                                </Button>
                            </Form.Group>

                            <Form.Group className="">
                                <p>
                                    <Link to="/request-password"><FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon> <FormattedMessage id="login-requestpassword"></FormattedMessage></Link>
                                    <br />
                                    <Link to="/register"><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> <FormattedMessage id="login-signup"></FormattedMessage></Link>
                                </p>
                            </Form.Group>

                        </div>
                    </form>
                </div>
            </div>

        </PublicLayout >
    );
};

export default Login;