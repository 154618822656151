import * as api from '../api';

export const UserSignout = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/sign_out', data)
            .then(response => {
                resolve(response.data);
                // remove also the localstorage items
                localStorage.removeItem("authentication");
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};

export const UserStatus = () => {
    // do something on the refresh token
    return new Promise((resolve, reject) => {
        api
            .get('/user/status')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};

export const UserLogin = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/login', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const RequestPassword = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/request_password', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const ConfirmRequestPassword = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/confirm_request_password', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const ChangePasswordForced = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/user/changepassword_forced', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const ChangePassword = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/user/changepassword', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const RegisterCustomer = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/registration/new_customer', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const AccountActivateService = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/registration/activate', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const AccountActivationStatus = (validation_token) => {
    return new Promise((resolve, reject) => {
        api
            .get('/registration/registration_status/' + validation_token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};