import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { NavLink } from 'react-router-dom';
import {
    Button, ButtonGroup,
    ButtonToolbar, NavItem, Nav
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faTrash, faMoneyBill, faUser } from "@fortawesome/free-solid-svg-icons";

import { ProtectedLayout } from './../../components/layouts';
import { CustomerDetails } from '../../services/customers';
import { ListCustomerInvoices, SearchInvoices } from './../../services/invoices';
import { CustomerDetailView } from './views';
import { AddCustomerModal, EditCustomerModal, DeleteCustomerModal } from './index';
import { InvoicesGridview } from './../invoices/index';

import './style.css';

const CustomerDetailPage = ({
    history,
    match,
    ...props
}) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [customerData, setCustomerData] = useState([]);

    const [addCustomerOpen, setAddCustomerOpen] = useState(false);
    const [editCustomerOpen, setEditCustomerOpen] = useState(false);
    const [deleteCustomerOpen, setDeleteCustomerOpen] = useState(false);

    const [tableLoading, setTableLoading] = useState(true);
    const [invoicesData, setInvoicesData] = useState([]);

    const [pageTab, setPageTab] = useState('details');
    const { addToast } = useToasts();
    const customer_id = match.params.customer_id;
    const pagetab = match.params.tab;

    useEffect(() => {
        if (pagetab) {
            setPageTab(pagetab);

            switch (pagetab) {
                case 'billing':
                    LoadInvoices();
                    break;

                default:
                    LoadCustomerData();
            }
        } else {
            setPageTab('details');
            LoadCustomerData();
        }

        return () => {
            // cleanup
            //setCustomerData({});
            setDataLoading(true);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPageTab, pagetab])

    const LoadCustomerData = () => {
        setDataLoading(true);
        CustomerDetails(customer_id)
            .then(response => {

                if (response.status) {
                    setCustomerData(response.data);
                } else {
                    history.push('/customers');
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
                setDataLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("Error loading customer data. Please refresh browser and try again!", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });

    };

    const LoadInvoices = () => {
        setTableLoading(true);
        setInvoicesData([]);

        ListCustomerInvoices(customer_id)
            .then(response => {
                setInvoicesData(response.data.invoice_list);
                setTableLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("Error loading customer data. Please refresh browser and try again!", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }

            });
    };

    // search bar handler
    const handleSearchBar = (p_SearchInput) => {
        setTableLoading(true);
        setInvoicesData([]);

        SearchInvoices(p_SearchInput)
            .then(response => {
                if (response.status) {
                    setInvoicesData(response.data.invoice_list);
                } else {
                    addToast(response.msg, {
                        appearance: 'warning',
                        autoDismiss: true,
                        // autoDismissTimeout: 30000,
                    });
                }

                setTableLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("Error loading customer data. Please refresh browser and try again!", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    return (
        <ProtectedLayout {...props} history={history}>
            <div className="row">
                <div className="col-12 mb-5">
                    <h5>
                        <FormattedMessage id="customer-details-page-header" />: {customerData.customer_name}.
                    </h5>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-5">
                    <div className="main-page-container customer-details-container">

                        {/* customer detail navigation tabs */}
                        <Nav variant="tabs">
                            <NavItem>
                                <NavLink to={`/customer/${customer_id}`} exact className="nav-link">
                                    <FontAwesomeIcon icon={faUser} /> <FormattedMessage id="customer-details" />
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink to={`/customer/${customer_id}/billing`} className="nav-link">
                                    <FontAwesomeIcon icon={faMoneyBill} /> <FormattedMessage id="customer-invoices" />
                                </NavLink>
                            </NavItem>

                            {/* <NavItem>
                                <NavLink to={`/customer/${customer_id}/subscriptions`} className="nav-link">
                                    <FormattedMessage id="customer-subscriptions" />
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink to={`/customer/${customer_id}/settings`} className="nav-link">
                                    <FormattedMessage id="customer-settings" />
                                </NavLink>
                            </NavItem> */}

                        </Nav>

                        <div className="customer-views-container">
                            {pageTab === 'details' ? (
                                <React.Fragment>
                                    {/* Header table toolbar */}
                                    <div className="btn-toolbar-container">
                                        <ButtonToolbar>
                                            <ButtonGroup className="ml-auto" size="smm">
                                                {/* <Button variant="outline-secondary" onClick={() => { setAddCustomerOpen(!addCustomerOpen) }}><FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon> <FormattedMessage id="customers-AddNewCustomer"></FormattedMessage></Button> */}
                                                <Button variant="outline-secondary" onClick={() => { history.push("/invoice/add/" + customer_id) }}><FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon> <FormattedMessage id="customer-AddInvoice" /></Button>
                                                <Button variant="outline-success" onClick={() => { setEditCustomerOpen(!editCustomerOpen) }}><FontAwesomeIcon icon={faUserEdit}></FontAwesomeIcon> <FormattedMessage id="customer-Edit" /></Button>
                                                <Button variant="outline-danger" onClick={() => { setDeleteCustomerOpen(!deleteCustomerOpen) }}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> <FormattedMessage id="customer-Delete" /></Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </div>

                                    <CustomerDetailView
                                        customerdata={customerData}
                                        dataloading={dataLoading}
                                        LoadCustomerData={LoadCustomerData}
                                        history={history}></CustomerDetailView>
                                </React.Fragment>
                            ) : null}


                            {pageTab === 'billing' ? (
                                <React.Fragment>
                                    <div className="customer-invoice-view">
                                        <InvoicesGridview
                                            LoadInvoices={LoadInvoices}
                                            handleSearchBar={handleSearchBar}
                                            invoicesData={invoicesData}
                                            tableLoading={tableLoading}
                                            history={history}
                                            customerID={customer_id}
                                        ></InvoicesGridview>
                                    </div>

                                </React.Fragment>
                            ) : null}

                        </div>


                    </div>
                </div>
            </div>

            {!dataLoading && (
                <EditCustomerModal modalIsOpen={editCustomerOpen} modalOpenerHandler={setEditCustomerOpen} customerData={customerData} LoadCustomerData={LoadCustomerData} history={history}></EditCustomerModal>
            )}
            <AddCustomerModal modalIsOpen={addCustomerOpen} modalOpenerHandler={setAddCustomerOpen}></AddCustomerModal>
            <DeleteCustomerModal modalIsOpen={deleteCustomerOpen} modalOpenerHandler={setDeleteCustomerOpen} customerData={customerData}></DeleteCustomerModal>

        </ProtectedLayout >
    );
};

export default CustomerDetailPage;