import React, { useState, useEffect } from "react";

import {
    Button,
    Modal
} from "react-bootstrap";

import { faTimes, faSpinner, faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { InvoiceSetPayment } from './../../../services/invoices';
import { useForm } from "react-hook-form";
import { FormatEuro } from '../../../components/helpers';
import { useHistory } from "react-router-dom";

const SetInvoicePaymentModal = ({
    modalIsOpen,
    modalOpenerHandler,
    invoiceData,
    refreshdata,
    customerSettings,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faCoins);
    const { addToast } = useToasts();
    const { register, handleSubmit, setValue, watch } = useForm(
        { defaultValues: { notification: false, payment: false, send_accountancy: true } }
    );

    const history = useHistory();
    useEffect(() => {
        setValue("contact_email", invoiceData.invoice_data.customer_data.contact_email)

        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceData])

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);

        // get the auth info from localstore
        var localstore_data = JSON.parse(localStorage.getItem("authentication"));

        const post_data = {
            invoice_id: invoiceData.invoice_id,
            customer_id: invoiceData.customer_id,
            send_notification: data.notification,
            invoice_number: invoiceData.invoice_number,
            send_copy: data.send_copy,
            send_accountancy: data.send_accountancy,
            user_email: localstore_data['user_email'],
            payment_amount: invoiceData.invoice_data.finance_data.total
        };

        InvoiceSetPayment(post_data)
            .then(response => {
                if (response.status) {
                    modalOpenerHandler(false);
                    setButtonDisabled(false);
                    setButtonIcon(faCoins);

                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    refreshdata();
                } else {
                    setButtonDisabled(false);
                    setButtonIcon(faCoins);
                };

            })
            .catch(error => {
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
                setButtonDisabled(false);
                setButtonIcon(faCoins);
            });
    };

    return (

        <Modal size="lg" show={modalIsOpen} onHide={() => { modalOpenerHandler(false) }}>


            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faCoins}></FontAwesomeIcon> <FormattedMessage id="invoice-payment"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><FormattedMessage id="invoice-payment-Header"></FormattedMessage> <strong>#{invoiceData.invoice_number}</strong></p>
                <div className="p-3 mb-2">

                    <p>
                        <label className="custom-control custom-checkbox">
                            <input
                                name="payment"
                                ref={register}
                                type="checkbox" className="custom-control-input" />
                            <label className="custom-control-label"></label>
                            <span className="custom-control-description"><FormattedMessage id="invoice-register-payment" /> <strong><FormatEuro amount={invoiceData.invoice_data.finance_data.total} /></strong></span>
                        </label>
                    </p>

                    <p>
                        <label className="custom-control custom-checkbox">
                            <input
                                name="notification"
                                ref={register}
                                type="checkbox" className="custom-control-input" />
                            <label className="custom-control-label"></label>
                            <span className="custom-control-description"><FormattedMessage id="invoice-send-payment-notification"></FormattedMessage></span>
                        </label>
                    </p>

                    {/* send copy of invoice to accountancy */}
                    <p>
                        <label className="custom-control custom-checkbox">
                            <input
                                name="send_accountancy"
                                ref={register}
                                type="checkbox" className="custom-control-input" />
                            <label className="custom-control-label"></label>
                            <span className="custom-control-description"><FormattedMessage id="invoice-send-accountacy"></FormattedMessage> <span className="font-weight-bold"> {customerSettings?.settings_data.mail_settings.mail_accountancy_address}</span></span>
                        </label>
                    </p>



                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => { modalOpenerHandler(false) }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> <FormattedMessage id="close"></FormattedMessage></Button>
                <Button variant="outline-success" disabled={buttonDisabled || !watch("payment", false)} onClick={handleSubmit(onSubmit)}><FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''} /> <FormattedMessage id="save-payment"></FormattedMessage></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SetInvoicePaymentModal;