import React, { useState } from "react";

import {
    Button,
    Form,
    Modal,
} from "react-bootstrap";

import { faUserPlus, faTimes, faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { InputMessage } from '../../../components/helpers';
import { COUNTRIES } from '../../../constants/countries';  // default for Netherlands = 154
import { CustomerAdd } from '../../../services/customers';

const AddCustomerModal = ({
    modalIsOpen,
    modalOpenerHandler,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faCheck);
    const [customerType, setCustomerType] = useState(false);        // false = private, true = business

    const { register, handleSubmit, errors, formState, setValue } = useForm();
    const { touched } = formState;
    const { addToast } = useToasts();
    const history = useHistory();


    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);
        const postdata = {
            "customer_name": data.customer_name,
            "customer_data": {
                "business_account": customerType,
                "contact_name": customerType ? data.contact_name : data.customer_name,
                "contact_phonenumber": data.contact_phonenumber,
                "customer_note": data.customer_note,
                "customer_reg_number": data.customer_reg_number,
                "customer_vat": data.customer_vat,
                "customer_address": {
                    "customer_city": data.customer_city,
                    "customer_country": COUNTRIES[parseInt(data.customer_country, 10)].name,
                    "customer_street": data.customer_street,
                    "customer_zipcode": data.customer_zipcode
                }
            }
        };

        // check if the optional mail address has entered
        if (data.contact_email) {
            postdata.customer_data = { ...postdata.customer_data, "contact_email": data.contact_email }
        }

        CustomerAdd(postdata)
            .then(response => {
                if (response.status) {
                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    history.push('/customer/' + response.data.customer_id);
                    //close modal
                    modalOpenerHandler(false);
                } else {
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                    setButtonDisabled(false);
                    setButtonIcon(faCheck);
                }


            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
                setButtonDisabled(false);
                setButtonIcon(faCheck);
            });

    };

    return (

        <Modal size="lg" show={modalIsOpen} onHide={() => modalOpenerHandler(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon> <FormattedMessage id="customers-AddNewCustomer"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><FormattedMessage id="customers-AddNewcustomer-Header"></FormattedMessage></p>
                <form>

                    {/* customer basic card */}
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Details"></FormattedMessage></h5>

                            {/* customer type (business or private) */}
                            <div className="form-group row align-items-center">
                                <label htmlFor="cutomertype" className="col-sm-4 col-form-label"><FormattedMessage id="customer-type"></FormattedMessage></label>
                                <div className="col-sm-8">
                                    <div className="custom-control custom-switch">
                                        <input
                                            ref={register({})}
                                            data-testid="customer_type"
                                            type="checkbox"
                                            name="customer_type"
                                            onChange={() => setCustomerType(!customerType)}
                                            checked={customerType}
                                            className="custom-control-input"
                                            id="customSwitch1" />
                                        <label className="custom-control-label" htmlFor="customSwitch1"><FormattedMessage id="customer-type-info-label" /></label>
                                    </div>
                                </div>
                            </div>

                            {/* customer_name */}
                            <div className="form-group row">
                                <label htmlFor="companyname" className="col-sm-4 col-form-label"><FormattedMessage id={customerType ? `company-name` : `customer-name`}></FormattedMessage></label>
                                <div className="col-sm-8">
                                    <FormattedMessage id={customerType ? `company-name` : `customer-name`}>
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })} type="text" id="#companyname"
                                                data-testid="customer_name"
                                                name="customer_name"
                                                placeholder={NameField}
                                                isValid={(touched.customer_name && !errors.customer_name) ? true : false}
                                                isInvalid={errors.customer_name ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.customer_name?.message}></InputMessage>
                                </div>
                            </div>

                            {/* Contact person */}
                            {customerType && (
                                <div className="form-group row">
                                    <label htmlFor="contact_name" className="col-sm-4 col-form-label"><FormattedMessage id="contact_name"></FormattedMessage></label>
                                    <div className="col-sm-8">
                                        <FormattedMessage id="contact_name">
                                            {NameField =>
                                                <Form.Control ref={register({
                                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                                })} type="text" id="#contact_name"
                                                    data-testid="contact_name"
                                                    name="contact_name"
                                                    placeholder={NameField}
                                                    isValid={(touched.contact_name && !errors.contact_name) ? true : false}
                                                    isInvalid={errors.contact_name ? true : false} />
                                            }
                                        </FormattedMessage>
                                        <InputMessage className="text-danger" fade={true} message={errors.contact_name?.message}></InputMessage>
                                    </div>
                                </div>
                            )}

                            {/* company registration number */}
                            {customerType && (
                                <div className="form-group row">
                                    <label htmlFor="customer_reg_number" className="col-sm-4 col-form-label"><FormattedMessage id="company-registration-number"></FormattedMessage></label>
                                    <div className="col-sm-8">
                                        <FormattedMessage id="company-registration-number">
                                            {NameField =>
                                                <Form.Control ref={register({
                                                    // required: { value: true, message: "inputfield-FieldIsRequired" },
                                                })} type="text" id="#customer_reg_number"
                                                    data-testid="customer_reg_number"
                                                    name="customer_reg_number"
                                                    placeholder={NameField}
                                                    isValid={(touched.customer_reg_number && !errors.customer_reg_number) ? true : false}
                                                    isInvalid={errors.customer_reg_number ? true : false} />
                                            }
                                        </FormattedMessage>
                                        <InputMessage className="text-danger" fade={true} message={errors.customer_reg_number?.message}></InputMessage>
                                    </div>
                                </div>
                            )}

                            {/* Company VAT number */}
                            {customerType && (
                                <div className="form-group row">
                                    <label htmlFor="customer_vat" className="col-sm-4 col-form-label"><FormattedMessage id="company-vat-number"></FormattedMessage></label>
                                    <div className="col-sm-8">
                                        <FormattedMessage id="company-vat-number">
                                            {NameField =>
                                                <Form.Control ref={register({
                                                    // required: { value: true, message: "inputfield-FieldIsRequired" },
                                                })} type="text" id="#customer_vat"
                                                    data-testid="customer_vat"
                                                    name="customer_vat"
                                                    placeholder={NameField}
                                                    isValid={(touched.customer_vat && !errors.customer_vat) ? true : false}
                                                    isInvalid={errors.customer_vat ? true : false} />
                                            }
                                        </FormattedMessage>
                                        <InputMessage className="text-danger" fade={true} message={errors.customer_vat?.message}></InputMessage>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>

                    {/* Customer address card */}
                    <div className="card mt-4">
                        <div className="card-body">
                            <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Address"></FormattedMessage></h5>

                            {/* Adres */}
                            <div className="form-group row">
                                <label htmlFor="streetAndNumber" className="col-sm-3 col-form-label"><FormattedMessage id="streetAndNumber"></FormattedMessage></label>
                                <div className="col-sm-9">
                                    <FormattedMessage id="streetAndNumber">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })} type="text" id="#streetAndNumber"
                                                data-testid="customer_street"
                                                name="customer_street"
                                                placeholder={NameField}
                                                isValid={(touched.customer_street && !errors.customer_street) ? true : false}
                                                isInvalid={errors.customer_street ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.customer_street?.message}></InputMessage>
                                </div>
                            </div>

                            {/* zipcode and city form */}
                            <div className="form-group row">
                                <label htmlFor="zipcode" className="col-sm-3 col-form-label"><FormattedMessage id="zipcodeAndCitty"></FormattedMessage></label>

                                {/* zipcode form */}
                                <div className="col-sm-4">
                                    <FormattedMessage id="zipcode">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })} type="text" id="#zipcode"
                                                data-testid="customer_zipcode"
                                                name="customer_zipcode"
                                                placeholder={NameField}
                                                isValid={(touched.customer_zipcode && !errors.customer_zipcode) ? true : false}
                                                isInvalid={errors.customer_zipcode ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.customer_zipcode?.message}></InputMessage>
                                </div>

                                {/* ciyt form */}
                                <div className="col-sm-5">
                                    <FormattedMessage id="city">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })} type="text"
                                                data-testid="customer_city"
                                                name="customer_city"
                                                placeholder={NameField}
                                                isValid={(touched.customer_city && !errors.customer_city) ? true : false}
                                                isInvalid={errors.customer_city ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.customer_city?.message}></InputMessage>
                                </div>

                            </div>

                            {/* Country */}
                            <div className="form-group row">
                                <label htmlFor="country" className="col-sm-3 col-form-label"><FormattedMessage id="country"></FormattedMessage></label>
                                <div className="col-sm-9">
                                    <FormattedMessage id="country">
                                        {NameField =>
                                            <Form.Control as="select" ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })} id="#country"
                                                data-testid="customer_country"
                                                name="customer_country"
                                                placeholder={NameField}
                                                isValid={(touched.customer_country && !errors.customer_country) ? true : false}
                                                isInvalid={errors.customer_country ? true : false}
                                                defaultValue="154">
                                                {COUNTRIES.map(title => (
                                                    <option key={title.id} value={title.id}>
                                                        {title.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.customer_country?.message}></InputMessage>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* customer contact card */}
                    <div className="card mt-4">
                        <div className="card-body">
                            <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Contact"></FormattedMessage></h5>

                            {/* phone numer */}
                            <div className="form-group row">
                                <label htmlFor="phonenumber" className="col-sm-3 col-form-label"><FormattedMessage id="contact-phonenumber"></FormattedMessage></label>
                                <div className="col-sm-9">
                                    <FormattedMessage id="contact-phonenumber">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                //required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })} type="text" id="#phonenumber"
                                                data-testid="contact_phonenumber"
                                                name="contact_phonenumber"
                                                placeholder={NameField}
                                                isValid={(touched.contact_phonenumber && !errors.contact_phonenumber) ? true : false}
                                                isInvalid={errors.contact_phonenumber ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.contact_phonenumber?.message}></InputMessage>
                                </div>
                            </div>

                            {/* contact mail address */}
                            <div className="form-group row">
                                <label htmlFor="emailaddress" className="col-sm-3 col-form-label"><FormattedMessage id="contact_emailaddress"></FormattedMessage></label>
                                <div className="col-sm-9">
                                    <FormattedMessage id="emailaddress">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                // required: { value: true, message: "inputfield-FieldIsRequired" },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                                    message: "EmailAddressNotValid",
                                                },
                                                // validate: {
                                                //     checkemail: async (value) => {
                                                //         if (!value) { return true };
                                                //         return await checkEmail(value, schoolID).then(response => {
                                                //             if (response.exists) { return 'EmailAddressAlreadyExist' };
                                                //         });
                                                //     },
                                                //     checkemailfrontend: (value) => { return checkEmailAlreadyUsed(value) }
                                                // },

                                            })} type="text"
                                                id="#emailaddress"
                                                data-testid="contact_email"
                                                name="contact_email"
                                                placeholder={NameField}
                                                onChange={e => { setValue("contact_email", e.target.value.toLowerCase()) }}
                                                isValid={(touched.contact_email && !errors.contact_email) ? true : false}
                                                isInvalid={errors.contact_email ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.contact_email?.message}></InputMessage>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* customer contact card */}
                    <div className="card mt-4">
                        <div className="card-body">
                            <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Notes"></FormattedMessage></h5>

                            <Form.Group>
                                <FormattedMessage id="customer_notes">
                                    {NameField =>
                                        <Form.Control as="textarea" ref={register({
                                            // required: { value: true, message: "inputfield-FieldIsRequired" },
                                        })} data-testid="customer_note"
                                            name="customer_note"
                                            placeholder={NameField}
                                            isValid={(touched.customer_note && !errors.customer_note) ? true : false}
                                            isInvalid={errors.customer_note ? true : false} />
                                    }
                                </FormattedMessage>
                                <InputMessage className="text-danger" fade={true} message={errors.customer_note?.message}></InputMessage>
                            </Form.Group>
                        </div>
                    </div>

                </form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => { modalOpenerHandler(false) }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> <FormattedMessage id="close"></FormattedMessage></Button>
                <Button variant="outline-success" disabled={buttonDisabled} onClick={handleSubmit(onSubmit)}><FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''} /> <FormattedMessage id="save"></FormattedMessage></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCustomerModal;