import React, { useState } from "react";

import {
    Button,
    Modal, Form
} from "react-bootstrap";

import { faTimes, faSpinner, faCheckSquare, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { ChangePassword } from './../../services/users';
import { useForm } from "react-hook-form";
import { InputMessage } from '../../components/helpers';

const ChangePasswordModal = ({
    modalIsOpen,
    modalOpenerHandler,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faCheckSquare);
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, formState, watch } = useForm();
    const { touched } = formState;

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);

        ChangePassword(data)
            .then(response => {
                if (response.status) {
                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    modalOpenerHandler(false);
                } else {
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    setButtonDisabled(false);
                    setButtonIcon(faCheckSquare);
                }


            })
            .catch(error => {
                setButtonDisabled(false);
                setButtonIcon(faCheckSquare);
            });
    };

    return (

        <Modal size="lg" show={modalIsOpen} onHide={() => { modalOpenerHandler(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon> <FormattedMessage id="changepassword"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><FormattedMessage id="change-password-header"></FormattedMessage></p>
                <div className="p-3 mb-2">
                    {/* current password field */}
                    <div className="form-group row">
                        <label htmlFor="current_password" className="col-sm-4 col-form-label"><FormattedMessage id="your-current-password"></FormattedMessage>:</label>
                        <div className="col-sm-7">
                            <FormattedMessage id="password">
                                {NameField =>
                                    <Form.Control ref={register({
                                        required: { value: true, message: "inputfield-FieldIsRequired" },
                                        minLength: { value: 8, message: "inputfield-minLength8" },
                                    })} type="password"
                                        id="#current_password"
                                        data-testid="current_password"
                                        name="current_password"
                                        placeholder={NameField}
                                        isValid={(touched.current_password && !errors.current_password) ? true : false}
                                        isInvalid={errors.current_password ? true : false} />
                                }
                            </FormattedMessage>
                            <InputMessage className="text-danger" fade={true} message={errors.current_password?.message}></InputMessage>
                        </div>
                    </div>

                    {/* new password field */}
                    <div className="form-group row">
                        <label htmlFor="new_password" className="col-sm-4 col-form-label"><FormattedMessage id="newpassword"></FormattedMessage>:</label>
                        <div className="col-sm-7">
                            <FormattedMessage id="newpassword">
                                {NameField =>
                                    <Form.Control ref={register({
                                        required: { value: true, message: "inputfield-FieldIsRequired" },
                                        minLength: { value: 8, message: "inputfield-minLength8" },
                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                            message: "inputfield-PasswordPatternNotMatch",
                                        },
                                    })} type="password"
                                        id="#new_password"
                                        data-testid="new_password"
                                        name="new_password"
                                        placeholder={NameField}
                                        isValid={(touched.new_password && !errors.new_password) ? true : false}
                                        isInvalid={errors.new_password ? true : false} />
                                }
                            </FormattedMessage>
                            <InputMessage className="text-danger" fade={true} message={errors.new_password?.message}></InputMessage>
                        </div>
                    </div>

                    {/* repeat new password field */}
                    <div className="form-group row">
                        <label htmlFor="confirm_new_password" className="col-sm-4 col-form-label"><FormattedMessage id="repeatpassword"></FormattedMessage>:</label>
                        <div className="col-sm-7">
                            <FormattedMessage id="repeatpassword">
                                {NameField =>
                                    <Form.Control ref={register({
                                        required: { value: true, message: "inputfield-FieldIsRequired" },
                                        minLength: { value: 8, message: "inputfield-minLength8" },
                                        validate: {
                                            compare: value => {
                                                if (watch('new_password') !== value) {
                                                    return 'PasswordsNotEqual';
                                                } else {
                                                    return undefined;
                                                };
                                            },
                                        }
                                    })} type="password"
                                        id="#confirm_new_password"
                                        data-testid="confirm_new_password"
                                        name="confirm_new_password"
                                        placeholder={NameField}
                                        isValid={(touched.confirm_new_password && !errors.confirm_new_password) ? true : false}
                                        isInvalid={errors.confirm_new_password ? true : false} />
                                }
                            </FormattedMessage>
                            <InputMessage className="text-danger" fade={true} message={errors.confirm_new_password?.message}></InputMessage>
                        </div>
                    </div>


                </div>

            </Modal.Body>


            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => { modalOpenerHandler(!modalIsOpen) }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> <FormattedMessage id="close"></FormattedMessage></Button>
                <Button variant="outline-success"
                    disabled={buttonDisabled}
                    onClick={handleSubmit(onSubmit)}><FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''} /> <FormattedMessage id="save-new-password"></FormattedMessage></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePasswordModal;