import React, { useState } from "react";

import { faSpinner, faArrowLeft, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import {
    Form,
    Button,
} from "react-bootstrap";

import { Link } from 'react-router-dom';
import { PublicLayout } from './../../components/layouts';
import { InputMessage } from '../../components/helpers';
import { RegisterCustomer } from '../../services/users';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
import { ReactComponent as FactuurtjeLogo } from './../../assets/images/factuurtje_online_logo_white.svg';

const SignupPage = ({ ...props }) => {

    const { register, handleSubmit, errors, formState, setValue } = useForm();
    const { touched } = formState;
    const { addToast } = useToasts();
    const history = useHistory();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faEdit);

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);

        RegisterCustomer({ ...data, business_account: true })
            .then(response => {
                if (response.status) {
                    history.push("/login");
                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                } else {
                    setButtonDisabled(false);
                    setButtonIcon(faEdit);
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                };
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
                setButtonDisabled(false);
                setButtonIcon(faEdit);
            });
    };

    return (
        <PublicLayout>
            <div className="login-container">

                <div className="login">

                    <div className="login-header">
                        {/* <FontAwesomeIcon icon={faMap}></FontAwesomeIcon> Factuurtje Online */}
                        <FactuurtjeLogo className="form-box-logo" />
                    </div>
                    <form className="login-form">
                        <div className="login-content">

                            <React.Fragment>
                                <p className="text-center"><FormattedMessage id="Signup-header"></FormattedMessage></p>

                                {/* Company name field */}
                                <Form.Group>
                                    <label htmlFor="#companyname"><FormattedMessage id="company-name"></FormattedMessage></label>
                                    <FormattedMessage id="company-name">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })} type="text" id="#companyname"
                                                data-testid="customer_name"
                                                name="customer_name"
                                                placeholder={NameField}
                                                isValid={(touched.customer_name && !errors.customer_name) ? true : false}
                                                isInvalid={errors.customer_name ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.customer_name?.message}></InputMessage>
                                </Form.Group>

                                {/* contact name field */}
                                <Form.Group>
                                    <label htmlFor="#yourname"><FormattedMessage id="your-name"></FormattedMessage></label>
                                    <FormattedMessage id="your-name">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })} type="text" id="#yourname"
                                                data-testid="contact_name"
                                                name="contact_name"
                                                placeholder={NameField}
                                                isValid={(touched.contact_name && !errors.contact_name) ? true : false}
                                                isInvalid={errors.contact_name ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.contact_name?.message}></InputMessage>
                                </Form.Group>

                                {/* email address field */}
                                <Form.Group>
                                    <label htmlFor="#emailaddress"><FormattedMessage id="your-emailaddress"></FormattedMessage></label>
                                    <FormattedMessage id="emailaddress">
                                        {NameField =>
                                            <Form.Control ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                                    message: "EmailAddressNotValid",
                                                },
                                                // validate: {
                                                //     checkemail: async (value) => {
                                                //         if (!value) { return true };
                                                //         return await checkEmail(value, schoolID).then(response => {
                                                //             if (response.exists) { return 'EmailAddressAlreadyExist' };
                                                //         });
                                                //     },
                                                //     checkemailfrontend: (value) => { return checkEmailAlreadyUsed(value) }
                                                // },

                                            })} type="text"
                                                id="#emailaddress"
                                                data-testid="customer_email"
                                                name="customer_email"
                                                placeholder={NameField}
                                                onChange={e => { setValue("user_email", e.target.value.toLowerCase()) }}
                                                isValid={(touched.customer_email && !errors.customer_email) ? true : false}
                                                isInvalid={errors.customer_email ? true : false} />
                                        }
                                    </FormattedMessage>
                                    <InputMessage className="text-danger" fade={true} message={errors.customer_email?.message}></InputMessage>
                                </Form.Group>

                                {/* Sign-up button */}
                                <Form.Group className="text-center">
                                    <Button
                                        data-testid="signup_button"
                                        disabled={buttonDisabled} block variant="outline-dark"
                                        onClick={handleSubmit(onSubmit)}>
                                        <FormattedMessage id="singup"></FormattedMessage> <FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''}></FontAwesomeIcon>
                                    </Button>
                                </Form.Group>
                            </React.Fragment>

                            <Form.Group className="">
                                <p>
                                    <Link to="/login"><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> <FormattedMessage id="back"></FormattedMessage></Link>
                                </p>
                            </Form.Group>
                        </div>
                    </form>
                </div>
            </div>

        </PublicLayout >
    );
};

export default SignupPage;
