import React, { useState, useEffect } from "react";
import {
    Button, ButtonGroup,
    ButtonToolbar, Tooltip,
    OverlayTrigger, Tab, Tabs
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileDownload, faPaperPlane, faCoins, //faBell,
    faCheckCircle, faTimesCircle, faQuestionCircle, faEdit, faBell, faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { isEqual } from 'lodash';
import { ProtectedLayout } from './../../components/layouts';
import { InvoiceDetails, DownloadInvoice } from './../../services/invoices';
import { CustomerSettings } from './../../services/customers';
import Spinner from "../../components/Spinner";
import Moment from 'react-moment';
import { FormatEuro, StatusBadge } from './../../components/helpers';
import { EditInvoiceModal, SendInvoiceModal, SetInvoicePaymentModal, SendInvoiceReminderModal } from './modals';
import { Link } from "react-router-dom";

const InvoiceViewPage = ({
    history,
    match,
    ...props
}) => {

    const [dataLoading, setDataLoading] = useState(true);
    const [invoiceData, setInvoiceData] = useState({});
    const [showHistoricalCustomerData, setShowHistoricalCustomerData] = useState(false);
    const [currentCustomerData, setCurrentCustomerData] = useState(null);
    const [historicalCustomerData, setHistoricalCustomerData] = useState(null);
    const [customerSettings, setCustomerSettings] = useState();
    const [hasSendPaymentReminders, setHasSendPaymentReminders] = useState(faTimesCircle);
    const [lastSendPaymentReminder, setLastSendPaymentReminder] = useState(undefined);
    const [openSendModal, setOpenSendModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openSendReminderModal, setOpenSendReminderModal] = useState(false);

    const invoice_id = match.params.invoice_id;
    const { addToast } = useToasts();

    useEffect(() => {
        setDataLoading(true);
        LoadInvoiceData();
        LoadingSettings();

        return () => {
            // cleanup
            setInvoiceData({});
            setDataLoading(true);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice_id])

    const LoadingSettings = () => {
        CustomerSettings("me")
            .then(response => {
                if (response.status) {
                    setCustomerSettings(response.data);
                } else {
                    addToast("Error on requesting settings data.", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    const LoadInvoiceData = () => {
        InvoiceDetails(invoice_id)
            .then(response => {
                if (response.status) {
                    setInvoiceData(response.data);

                    const current_customer_data = {
                        contact_name: response.data.current_customer_data.customer_data.contact_name,
                        customer_city: response.data.current_customer_data.customer_data.customer_address.customer_city,
                        customer_country: response.data.current_customer_data.customer_data.customer_address.customer_country,
                        customer_debtor_number: response.data.current_customer_data.customer_debtor_number.toString(),
                        customer_name: response.data.current_customer_data.customer_name,
                        customer_street: response.data.current_customer_data.customer_data.customer_address.customer_street,
                        customer_zipcode: response.data.current_customer_data.customer_data.customer_address.customer_zipcode,
                        business_account: response.data.current_customer_data.customer_data.business_account,
                        contact_email: response.data.current_customer_data.customer_data.contact_email
                    };
                    setCurrentCustomerData(<React.Fragment>
                        <strong>{response.data.customer_name}</strong><br />
                        {response.data.current_customer_data.customer_data.business_account && <span>{response.data.current_customer_data.customer_data.contact_name}<br /></span>}
                        {response.data.current_customer_data.customer_data.customer_address.customer_street}<br />
                        {response.data.current_customer_data.customer_data.customer_address.customer_zipcode} {response.data.current_customer_data.customer_data.customer_address.customer_city}<br />
                        {response.data.current_customer_data.customer_data.contact_email}<br />
                    </React.Fragment>
                    );

                    const historic_customer_data = response.data.invoice_data.customer_data;
                    setHistoricalCustomerData(<React.Fragment>
                        <strong>{response.data.customer_name}</strong><br />
                        {response.data.invoice_data.customer_data.business_account && <span>{response.data.invoice_data.customer_data?.contact_name}<br /></span>}
                        {response.data.invoice_data.customer_data.customer_street}<br />
                        {response.data.invoice_data.customer_data.customer_zipcode} {response.data.invoice_data.customer_data.customer_city}<br />
                        {response.data.invoice_data.customer_data.contact_email}<br />
                    </React.Fragment>);

                    if (!isEqual(current_customer_data, historic_customer_data)) {
                        setShowHistoricalCustomerData(true);
                    }

                    // check if reminders has send
                    if (response.data.invoice_history.length > 0) {
                        for (let i = 0; i < response.data.invoice_history.length; i++) {
                            if (response.data.invoice_history[i].action === "INVOICE_PAYMENT_REMINDER_SEND_BY_MAIL") {
                                setHasSendPaymentReminders(faCheckCircle);
                                setLastSendPaymentReminder(response.data.invoice_history[i].date);
                                break;
                            } else {
                                setHasSendPaymentReminders(faTimesCircle);
                            }
                        };
                    }

                } else {
                    history.push('/invoices');
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
                setDataLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    const refreshInvoice = () => {
        LoadInvoiceData();
    };

    const refreshInvoicedata = () => {
        setDataLoading(true);
        LoadInvoiceData();
    };

    const UpdatePaymentReminder = () => {
        setHasSendPaymentReminders(faSpinner);
        // small timeout before update data.
        setTimeout(() => {
            LoadInvoiceData();
        }, 30000);
    };

    const DownloadInvoiceHandler = () => {
        const url = `https://${process.env.REACT_APP_AWS_S3}/customer/invoice/${invoiceData.vendor_id}/${invoiceData.customer_id}/${invoiceData.invoice_id}.pdf`
        DownloadInvoice(url)
            .then(response => {
                var binaryData = [];
                binaryData.push(response);
                var blobUrl = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));

                var link = document.createElement("a");
                link.style = "visibility:hidden";
                link.href = blobUrl;
                link.download = `factuur-${invoiceData.invoice_number}.pdf`;
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                addToast("Error while downloading invoice. Please try again later.", {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 30000,
                });
            });
    };


    return (
        <ProtectedLayout {...props} history={history}>
            {dataLoading ? (<Spinner></Spinner>) : (
                <React.Fragment>
                    <div className="row">
                        <div className="col-12 mb-5">
                            <h5>
                                <FormattedMessage id="invoice-view-page-header" />: {invoiceData.customer_name}
                            </h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-5">
                            <div className="main-page-container main-page-container--background invoice-details-container">

                                {/* customer & invoice info */}
                                <div className="row">
                                    {/* customer details */}
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Details"></FormattedMessage></h5>

                                                {showHistoricalCustomerData ? (
                                                    <React.Fragment>
                                                        <Tabs defaultActiveKey="invoice" className="customer-tabs">
                                                            <Tab eventKey="invoice" title={<FormattedMessage id="invoice-previous-address" />}>
                                                                <Link to={`/customer/${invoiceData.customer_id}`}>
                                                                    <FormattedMessage id="customer-debtornumber" />: #{invoiceData.invoice_data.customer_data.customer_debtor_number}<br />
                                                                    {historicalCustomerData}
                                                                </Link>
                                                                <OverlayTrigger overlay={<Tooltip><FormattedMessage id="invoice-previous-address-tt" /></Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        <small className="small"><FontAwesomeIcon icon={faQuestionCircle} /> <FormattedMessage id="tooltip-explain" /></small>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </Tab>

                                                            <Tab eventKey="current" title={<FormattedMessage id="invoice-current-address" />}>
                                                                <Link to={`/customer/${invoiceData.customer_id}`}>
                                                                    <FormattedMessage id="customer-debtornumber" />: #{invoiceData.invoice_data.customer_data.customer_debtor_number}<br />
                                                                    {currentCustomerData}
                                                                </Link>

                                                            </Tab>
                                                        </Tabs>
                                                    </React.Fragment>
                                                ) : (
                                                    <Link to={`/customer/${invoiceData.customer_id}`}>
                                                        <FormattedMessage id="customer-debtornumber" />: #{invoiceData.invoice_data.customer_data.customer_debtor_number}<br />
                                                        {currentCustomerData}
                                                    </Link>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-2"></div>

                                    {/* invoice details */}
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title"><FormattedMessage id="invoice-details" /></h5>
                                                <table>
                                                    <tbody>

                                                        <tr>
                                                            <td><FormattedMessage id="invoice-number" />:</td>
                                                            <td className="pl-2">#{invoiceData.invoice_number}</td>
                                                        </tr>

                                                        <tr>
                                                            <td><FormattedMessage id="invoice-date" />:</td>
                                                            <td className="pl-2"><Moment format="DD-MM-YYYY" date={invoiceData.invoice_date} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td><FormattedMessage id="invoice_status" />:</td>
                                                            <td className="pl-2">
                                                                {invoiceData.invoice_status === "OPEN" && Date.parse(invoiceData.invoice_data.due_date) < new Date() ?
                                                                    (<StatusBadge status="OVER_DUE" />)
                                                                    : (<StatusBadge status={invoiceData.invoice_status} />)}


                                                                {invoiceData.invoice_status === 'ERROR_CREATING' ? (
                                                                    <OverlayTrigger overlay={<Tooltip>Check your settings! Go to profile and choose settings.</Tooltip>}>
                                                                        <span className="d-inline-block">
                                                                            <span> <FontAwesomeIcon icon={faQuestionCircle} /></span>
                                                                        </span>
                                                                    </OverlayTrigger>

                                                                ) : (null)}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td><FormattedMessage id="invoice-send-to" />:</td>
                                                            <td className="pl-2">
                                                                {invoiceData.invoice_data.customer_data.contact_email ? (
                                                                    <span>{invoiceData.invoice_data.customer_data.contact_email}</span>
                                                                ) : (
                                                                    <span>{invoiceData.current_customer_data.customer_data.contact_email}</span>
                                                                )}

                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td><FormattedMessage id="invoice-send-by-mail" />:</td>
                                                            <td className="pl-2">
                                                                {invoiceData.invoice_send ? (
                                                                    <FontAwesomeIcon className="icon-success" icon={faCheckCircle} />
                                                                ) : (
                                                                    <FontAwesomeIcon className="icon-danger" icon={faTimesCircle} />
                                                                )
                                                                }
                                                            </td>
                                                        </tr>

                                                        {invoiceData.invoice_status === "OPEN" && Date.parse(invoiceData.invoice_data.due_date) < new Date() && (
                                                            <tr>
                                                                <td><FormattedMessage id="invoice-payment-reminder-send" />:</td>
                                                                <td className="pl-2">
                                                                    <FontAwesomeIcon className={hasSendPaymentReminders.iconName === 'spinner' ? 'fa-spin' : hasSendPaymentReminders.iconName === 'times-circle' ? 'icon-danger' : 'icon-success'} icon={hasSendPaymentReminders} />
                                                                    &nbsp;
                                                                    {lastSendPaymentReminder && (
                                                                        <OverlayTrigger overlay={<Tooltip><FormattedMessage id="invoice-payment-reminder-send-tt" /></Tooltip>}>
                                                                            <small className="small">
                                                                                <Moment format="DD-MM-YYYY" date={lastSendPaymentReminder} />
                                                                                <span className="d-inline-block">
                                                                                    &nbsp;<FontAwesomeIcon icon={faQuestionCircle} />
                                                                                </span>
                                                                            </small>
                                                                        </OverlayTrigger>
                                                                    )}

                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* buttons toolbar */}
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <ButtonToolbar>
                                            <ButtonGroup className="ml-auto" size="sm">

                                                <React.Fragment>
                                                    <Button size="sm" variant="outline-secondary" onClick={() => DownloadInvoiceHandler()}>
                                                        <FontAwesomeIcon icon={faFileDownload} /> <FormattedMessage id="invoice-download" /></Button>

                                                    <Button size="sm" variant="outline-secondary" onClick={() => setOpenSendModal(!openSendModal)}>
                                                        <FontAwesomeIcon icon={faPaperPlane} /> <FormattedMessage id="invoice-send" />
                                                    </Button>

                                                    {invoiceData.invoice_status !== 'PROCESSING' && invoiceData.invoice_status !== 'PAYED' && (
                                                        <Button size="sm" variant="outline-secondary" onClick={() => { setOpenEditModal(!openEditModal) }}>
                                                            <FontAwesomeIcon icon={faEdit} /> <FormattedMessage id="invoice-edit" />
                                                        </Button>
                                                    )}

                                                    {invoiceData.invoice_status !== "PAYED" && (
                                                        <Button size="sm" variant="outline-success" onClick={() => { setOpenPaymentModal(!openPaymentModal) }}>
                                                            <FontAwesomeIcon icon={faCoins} /> <FormattedMessage id="invoice-payment" />
                                                        </Button>
                                                    )}

                                                    {invoiceData.invoice_status !== "PAYED" && Date.parse(invoiceData.invoice_data.due_date) < new Date() && (
                                                        <Button variant="outline-warning" onClick={() => { setOpenSendReminderModal(!openSendReminderModal) }}>
                                                            <FontAwesomeIcon icon={faBell} /> <FormattedMessage id="invoice-reminder" />
                                                        </Button>
                                                    )}

                                                </React.Fragment>

                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </div>
                                </div>

                                {/* table invoice data */}
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="table-responsive-xl add-invoice-table">
                                            <table className="table add-invoice-table--rows">
                                                <thead className="table-header">
                                                    <tr>
                                                        <th width="10%"><FormattedMessage id="quantity"></FormattedMessage></th>
                                                        <th><FormattedMessage id="description"></FormattedMessage></th>
                                                        <th width="10%"><FormattedMessage id="amount"></FormattedMessage></th>
                                                        <th width="10%" className="text-right"><FormattedMessage id="vat" /></th>
                                                        <th width="15%" className="text-right"><FormattedMessage id="total"></FormattedMessage></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {invoiceData.invoice_lines.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.quantity}</td>
                                                                <td>{item.description}</td>
                                                                <td><FormatEuro amount={item.amount} /></td>
                                                                <td className="text-right">{customerSettings?.settings_data?.invoice_vat_list.find(find_vat => { return find_vat.vat_key === item.vat_key })?.vat_percentage}%</td>
                                                                <td className="text-right"> <FormatEuro amount={item.quantity * item.amount} /></td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {/* total table */}
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col-3"></div>
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td className="invoice-col"><FormattedMessage id="invoice-total-ex-vat" />:</td>
                                                            <td className="pl-2 text-right"><FormatEuro amount={invoiceData.invoice_data.finance_data.total_ex} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="invoice-col"><FormattedMessage id="invoice-total-vat" />:</td>
                                                            <td className="pl-2 text-right"><FormatEuro amount={invoiceData.invoice_data.finance_data.total_vat} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="invoice-col"><FormattedMessage id="invoice-total" />:</td>
                                                            <td className="pl-2 text-right"><FormatEuro amount={invoiceData.invoice_data.finance_data.total} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {openPaymentModal && (
                        <SetInvoicePaymentModal
                            invoiceData={invoiceData}
                            modalIsOpen={openPaymentModal}
                            modalOpenerHandler={setOpenPaymentModal}
                            refreshdata={refreshInvoicedata}
                            customerSettings={customerSettings} />
                    )}

                    {openSendModal && (
                        <SendInvoiceModal
                            invoiceData={invoiceData}
                            modalIsOpen={openSendModal}
                            modalOpenerHandler={setOpenSendModal}
                            refreshInvoice={refreshInvoice}
                            customerSettings={customerSettings} />
                    )}

                    {openEditModal && (
                        <EditInvoiceModal
                            invoiceData={invoiceData}
                            modalIsOpen={openEditModal}
                            modalOpenerHandler={setOpenEditModal}
                            history={history} />
                    )}

                    {openSendReminderModal && (
                        <SendInvoiceReminderModal
                            invoiceData={invoiceData}
                            modalIsOpen={openSendReminderModal}
                            modalOpenerHandler={setOpenSendReminderModal}
                            callback={UpdatePaymentReminder} />
                    )}

                </React.Fragment>

            )
            }

        </ProtectedLayout >
    );
};

export default InvoiceViewPage;
