import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { UserStatus } from '../users';
import { useToasts } from 'react-toast-notifications';

export function useFetchAuth({ required } = {}) {
    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [auth, setAuth] = useState();
    const { addToast } = useToasts();

    useEffect(() => {
        if (!loading && auth) { return; };

        //setLoading(true);
        let isMounted = true;

        // Only set the user if the component is still mounted
        if (isMounted) {
            // When the user is not logged in but login is required
            if (required && !auth) {

                UserStatus()
                    .then(response => {
                        if (response.status && response?.data.authenticated) {
                            setLoading(false);
                            setAuth(response.data);
                        } else {
                            history.push("/login");
                            return;
                        };
                    })
                    .catch(error => {
                        console.error('error UserStatus: ', error);
                        if (error?.status === 403) {
                            setLoading(false);
                            history.push("/login");
                        } else {
                            addToast("Your session has expired, please login to continue.", {
                                appearance: 'warning',
                                autoDismiss: true,
                            });
                        }
                        history.push("/login");
                        // return null;
                    });
            } else {
                // authorisation is not needed, remove loading
                setLoading(false);
                return;
            }
        } else {
            setLoading(false);
        }

        return () => {
            isMounted = false;
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return { auth, loading };
};