import React, { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { ProtectedLayout } from './../../components/layouts';
import { CustomerDetails, CustomerSettings } from './../../services/customers';
import {
    DeleteDraftInvoiceModal, SaveInvoiceModal,
    WarningInvoiceModal, WarningInvoiceVATModal
} from './modals';
import { InvoiceTemplate } from './index';
import { useForm, useWatch } from "react-hook-form";

const InvoiceAddPage = ({
    history,
    match,
    ...props
}) => {

    const [customerSettings, setCustomerSettings] = useState();
    const [invoiceData, setInvoiceData] = useState({ invoice_data: {} });
    const [invoiceWarningData, setInvoiceWarningData] = useState({});
    const [customerID, setCustomerID] = useState(undefined);

    // data loading variablen
    const [invoiceDataLoading, setInvoiceDataLoading] = useState(true);
    const [customerDataLoading, setCustomerDataLoading] = useState(true);
    const [customerSettingsDataLoading, setCustomerSettingsDataLoading] = useState(true);

    // modal open settings
    const [openDeleteDraftInvoice, setOpenDeleteDraftInvoice] = useState(false);
    const [openSaveInvoice, setOpenSaveInvoice] = useState(false);
    const [openInvoiceWarning, setopenInvoiceWarning] = useState(false);
    const [openInvoiceVATWarning, setopenInvoiceVATWarning] = useState(false);

    // other settings
    const { addToast } = useToasts();
    const { register, control, reset, watch } = useForm({
        defaultValues: { invoiceLines: [{ quantity: '1', description: '', amount: '', vat_total: '0' }] }
    });
    const invoiceLines = useWatch({ name: "invoiceLines", control });

    // start loading customer data and customer settings
    useEffect(() => {
        if (match.params.customer_id) {
            setCustomerID(match.params.customer_id);
            LoadCustomerData(match.params.customer_id);
            LoadingSettings();
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])

    // effect on checking if all data has loaded
    useEffect(() => {
        if (!customerSettingsDataLoading && !customerDataLoading) {
            setInvoiceDataLoading(false);
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerDataLoading, customerSettingsDataLoading])

    // effect for saving invoiceLines to localstore
    useEffect(() => {
        // only add to localstore if new invoice is creating
        if (invoiceLines && customerID) {
            // add changes to localstore
            localStorage.setItem(customerID, JSON.stringify(invoiceLines));
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceLines]);

    // effect on loading invoicelines from localstore
    useEffect(() => {
        var localstore_data = localStorage.getItem(customerID);
        if (localstore_data) {
            if (JSON.parse(localstore_data).length > 0) {
                reset({ invoiceLines: JSON.parse(localstore_data) });
            }
        }

        if (!invoiceDataLoading && invoiceData?.invoice_lines?.length > 0) {
            reset({ invoiceLines: invoiceData.invoice_lines });
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDataLoading]);

    // loading settings from the invoice creator (vendor)
    const LoadingSettings = () => {
        CustomerSettings("me")
            .then(response => {
                if (response.status) {
                    setCustomerSettings(response.data);
                    setCustomerSettingsDataLoading(false);
                } else {
                    addToast("Error on requesting settings data.", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    const LoadCustomerData = (p_CustomerID) => {
        CustomerDetails(p_CustomerID)
            .then(response => {
                if (response.status) {
                    setInvoiceData({
                        customer_id: response.data.customer_id,
                        invoice_data: {
                            customer_data: {
                                customer_name: response.data.customer_name,
                                contact_name: response.data.customer_data.contact_name,
                                contact_email: response.data.customer_data.contact_email,
                                customer_vat: response.data.customer_data.customer_vat,
                                customer_debtor_number: response.data.customer_debtor_number.toString(),
                                business_account: response.data.customer_data.business_account,
                                ...response.data.customer_data.customer_address,
                            },
                            finance_data: {},
                            invoice_lines: []
                        }
                    }, setCustomerDataLoading(false));

                } else {
                    history.push('/customers');
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }

            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    const checkInvoiceCreating = () => {
        return new Promise((resolve, reject) => {
            CustomerDetails("me").then(response => {
                // check if fields has been setup:
                // customer_vat, customer_reg_number
                // customer_address: customer_street, customer_zipcode, customer_city
                setInvoiceWarningData({ ...response.data.customer_data, ...customerSettings?.settings_data.payment_settings });

                if (Object.entries(response.data.customer_data.customer_address).length < 4) {
                    // if address is not completly set, length is lower then 4 details are not total filled in
                    setopenInvoiceWarning(true);
                    resolve(false);
                }

                if (!response.data.customer_data.customer_vat) {
                    setopenInvoiceWarning(true);
                    resolve(false);
                }

                if (!response.data.customer_data.customer_reg_number) {
                    setopenInvoiceWarning(true);
                    resolve(false);
                }

                if (!customerSettings?.settings_data.payment_settings.payment_account_number) {
                    setopenInvoiceWarning(true);
                    resolve(false);
                }

                if (!customerSettings?.settings_data.payment_settings.payment_account_name) {
                    setopenInvoiceWarning(true);
                    resolve(false);
                }

                resolve(true);
            });
        });
    };

    const saveInvoice = () => {
        // first check if al details are met to create an invoice
        checkInvoiceCreating().then(response => {
            if (response) {
                // check if the invoice has shifted vat.
                canInvoiceShiftedVat().then(response => {
                    if (response) {
                        // open model to ask what to do
                        setOpenSaveInvoice(true);
                    }
                });
            }
        })
    };

    const canInvoiceShiftedVat = () => {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < invoiceData.invoice_lines.length; i++) {
                if (invoiceData.invoice_lines[i].vat_key === "vat_0") {
                    if (!invoiceData.invoice_data.customer_data.customer_vat) {
                        setopenInvoiceVATWarning(true)
                        resolve(false);
                    }
                    break;
                }
            };
            resolve(true);
        });
    };

    return (
        <ProtectedLayout {...props} history={history}>
            <div className="row">
                <div className="col-12 mb-5">
                    <h5>
                        <FormattedMessage id="invoice-create-page-header" />: {invoiceData.invoice_data.customer_data?.customer_name}
                    </h5>
                </div>
            </div>

            <InvoiceTemplate
                saveInvoice={saveInvoice}
                setOpenDeleteDraftInvoice={setOpenDeleteDraftInvoice}
                invoiceDataLoading={invoiceDataLoading}
                invoiceData={invoiceData}
                customerSettings={customerSettings}
                setInvoiceData={setInvoiceData}
                register={register}
                control={control}
                watch={watch}
                invoiceLines={invoiceLines}
            />

            {!invoiceDataLoading && (
                <DeleteDraftInvoiceModal
                    customerData={invoiceData.invoice_data.customer_data}
                    modalIsOpen={openDeleteDraftInvoice}
                    modalOpenerHandler={setOpenDeleteDraftInvoice}
                    history={history}
                />
            )}

            {!invoiceDataLoading && (
                <SaveInvoiceModal
                    invoiceData={invoiceData}
                    modalIsOpen={openSaveInvoice}
                    modalOpenerHandler={setOpenSaveInvoice}
                    history={history}
                // invoiceID={invoice_id}
                />
            )}

            {!invoiceDataLoading && (
                <WarningInvoiceModal
                    invoiceWarningData={invoiceWarningData}
                    modalIsOpen={openInvoiceWarning}
                    modalOpenerHandler={setopenInvoiceWarning}
                    history={history} />
            )}

            {!invoiceDataLoading && (
                <WarningInvoiceVATModal
                    modalIsOpen={openInvoiceVATWarning}
                    modalOpenerHandler={setopenInvoiceVATWarning}
                    history={history}
                    customerID={customerID}
                />
            )}
        </ProtectedLayout>
    );
};

export default InvoiceAddPage;