import * as api from '../api';
import axios from 'axios';

export const ListInvoices = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/invoice/list', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};

export const DownloadInvoice = (p_URL) => {
    // download binary data for invoices
    return (
        axios({
            url: p_URL,
            method: 'get'
        })
            .then(response => response.data)
            .catch(error => Promise.reject(error.response))
    );
};

export const ListCustomerInvoices = (customer_id) => {
    return new Promise((resolve, reject) => {
        api
            .get('/invoice/list/' + customer_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};

export const InvoiceDetails = (invoice_id) => {
    return new Promise((resolve, reject) => {
        api
            .get('/invoice/details/' + invoice_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const InvoiceAdd = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/invoice/add', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const InvoiceAddDraft = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/invoice/add_draft', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const SearchInvoices = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/invoice/search', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const InvoiceUpdate = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/invoice/update', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const InvoiceSetPayment = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/invoice/set_payment', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const InvoiceSend = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/invoice/send', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

// InvoiceSendReminder
export const InvoiceSendReminder = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/invoice/sendreminder', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const InvoiceSetStatus = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/invoice/status', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const InvoiceDelete = (data) => {
    return new Promise((resolve, reject) => {
        api
            .remove('/invoice/delete', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};
