import React, { useState } from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

import { faTimes, faSpinner, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { InvoiceSetStatus } from '../../../services/invoices';

const EditInvoiceModal = ({
    modalIsOpen,
    modalOpenerHandler,
    invoiceData,
    history,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faEdit);
    const [confirmEdit, setConfirmEdit] = useState(false);
    const { addToast } = useToasts();

    const onSubmit = () => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);
        setConfirmEdit(false);

        InvoiceSetStatus({ invoice_id: invoiceData.invoice_id }).then(response => {
            modalOpenerHandler(false);
            history.push('/invoice/edit/' + invoiceData.invoice_id);
            addToast('Invoice status has been changed!', {
                appearance: 'success',
                autoDismiss: true,
            });
        });
    };

    return (

        <Modal size="lg" show={modalIsOpen} onHide={() => { modalOpenerHandler(!modalIsOpen) }}>

            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> <FormattedMessage id="invoice-edit"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-warning">
                <p><FormattedMessage id="invoice-edit-Header"></FormattedMessage></p>
                <div className="p-3 mb-2">
                    <p>
                        <FormattedMessage id="invoice-edit-info"></FormattedMessage> <strong>#{invoiceData.invoice_number}</strong>?
                    </p>
                    <p>
                        <label className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                checked={confirmEdit}
                                onChange={e => setConfirmEdit(!confirmEdit)}
                                className="custom-control-input" />
                            <label className="custom-control-label"></label>
                            <span className="custom-control-description"><FormattedMessage id="invoice-edit-confirm"></FormattedMessage></span>
                        </label>

                    </p>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => { modalOpenerHandler(!modalIsOpen) }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> <FormattedMessage id="close"></FormattedMessage></Button>
                <Button variant="outline-warning" disabled={!confirmEdit} onClick={onSubmit}><FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''} /> <FormattedMessage id="invoice-edit"></FormattedMessage></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditInvoiceModal;