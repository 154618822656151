import React, { useState, useEffect } from "react";

import { useToasts } from 'react-toast-notifications';
import { CheckPaymentStatus } from '../../services/payments';
import { faCheckCircle, faCoins, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { PublicLayout } from './../../components/layouts';
import { ReactComponent as FactuurtjeLogo } from './../../assets/images/factuurtje_online_logo_white.svg';
// import { FormatEuro } from '../../components/helpers';
import Spinner from "../../components/Spinner/index";
import { Link } from 'react-router-dom';

const InvoicePaymentStatusViewPage = ({
    history,
    match,
    ...props
}) => {
    const [invoiceID, setInvoiceID] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const [paymentError, setPaymentError] = useState(false);
    const { addToast } = useToasts();

    // check if invoice_id has been set
    useEffect(() => {
        if (match.params) {
            setInvoiceID(match.params.invoice_id);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match]);

    // when invoiceID has loaded loading next steps
    useEffect(() => {
        if (invoiceID) {
            CheckPaymentStatus({ "invoice_id": invoiceID }).then(response => {
                if (response.status) {
                    setLoadingData(false);
                } else {
                    setLoadingData(false);
                    setPaymentError(true);
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceID]);


    return (
        <PublicLayout>
            <div className="login-container">
                <div className="payment">

                    <div className="login-header">
                        <FactuurtjeLogo className="form-box-logo" />
                    </div>
                    <form className="login-form">
                        {(loadingData) ? (<div className="login-content">
                            <Spinner />
                        </div>) : (

                                <div className="login-content">
                                    {paymentError ? (<div>
                                        <p className="text-center font-weight-bolder h5"><FormattedMessage id="invoice-online-payment-not-found"></FormattedMessage></p>
                                        <div className="text-center text-warning  mt-4 mb-4">
                                            <FontAwesomeIcon className="fa-5x" icon={faInfoCircle} />
                                        </div>
                                        <div className="text-center"><FormattedMessage id="invoice-online-payment-not-found-info"></FormattedMessage></div>
                                        <div className="text-center mt-3">
                                            <div className="btn btn-outline-dark">
                                                <Link to={`/invoice/payment/${invoiceID}`}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon> <FormattedMessage id="invoice-online-payment" /></Link>
                                            </div>
                                        </div>

                                    </div>) :
                                        (<React.Fragment>
                                            <p className="text-center font-weight-bolder h5"><FormattedMessage id="invoice-online-payment-done"></FormattedMessage></p>
                                            <div className="text-center text-warning  mt-4 mb-4">
                                                <FontAwesomeIcon className="fa-5x text-success" icon={faCheckCircle} />
                                            </div>
                                            <div className="text-center"><FormattedMessage id="invoice-online-payment-done-info"></FormattedMessage></div>



                                        </React.Fragment>)
                                    }
                                </div>
                            )}
                    </form>
                </div>
            </div>

        </PublicLayout >
    );
};

export default InvoicePaymentStatusViewPage;