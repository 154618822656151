import React, { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';

import { ProtectedLayout } from './../../components/layouts';

import { ListInvoices, SearchInvoices } from '../../services/invoices';
import { InvoicesGridview } from './index';

// import AddCustomerModal from './modals/customer.add.modal';

// import './style.css';

const InvoicePage = ({
    history,
    match,
    ...props
}) => {
    const [tableLoading, setTableLoading] = useState(true);
    const [invoicesData, setInvoicesData] = useState([]);
    const [invoiceStatus, setInvoicesStatus] = useState("OPEN");

    const { addToast } = useToasts();
    const pagestatus = match.params.mode

    useEffect(() => {

        LoadInvoices();

        return () => {
            // cleanup
            setInvoicesData([]);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setInvoicesData, pagestatus, invoiceStatus])

    const LoadInvoices = () => {
        setTableLoading(true);
        setInvoicesData([]);

        ListInvoices({ invoice_status: invoiceStatus })
            .then(response => {
                setInvoicesData(response.data.invoice_list);
                setTableLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    // search bar handler
    const handleSearchBar = (p_SearchInput) => {
        setTableLoading(true);
        setInvoicesData([]);

        SearchInvoices(p_SearchInput)
            .then(response => {
                if (response.status) {
                    setInvoicesData(response.data.invoice_list);
                } else {
                    addToast(response.msg, {
                        appearance: 'warning',
                        autoDismiss: true,
                        // autoDismissTimeout: 30000,
                    });
                }

                setTableLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    return (
        <ProtectedLayout {...props} history={history}>
            <div className="row">
                <div className="col-12 mb-5">
                    <h5>
                        <FormattedMessage id="invoice-list-page-header" />
                    </h5>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-5">
                    <div className="main-page-container main-page-container--background">
                        <InvoicesGridview
                            LoadInvoices={LoadInvoices}
                            handleSearchBar={handleSearchBar}
                            invoicesData={invoicesData}
                            tableLoading={tableLoading}
                            history={history}
                            setInvoicesStatus={setInvoicesStatus}
                        ></InvoicesGridview>
                    </div>
                </div>
            </div>

        </ProtectedLayout>
    );
};

export default InvoicePage;