import React, { useState, useEffect } from "react";

import { NavItem, Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { NavLink } from 'react-router-dom';

import {
    Button,
    ButtonGroup,
    ButtonToolbar
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCoins, faSpinner, faUser, faUserEdit } from "@fortawesome/free-solid-svg-icons";

import { ProtectedLayout } from './../../components/layouts';
import { CustomerDetails, CustomerSettings } from '../../services/customers';
import { CustomerDetailView, CustomerSettingsView } from './../customers/views';
import { EditCustomerModal } from './../customers/index';
import { InvoicesGridview } from './../invoices';

const ProfileDetailPage = ({
    history,
    match,
    ...props
}) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [dataSettingsLoading, setDataSttingsLoading] = useState(true);
    const [customerData, setCustomerData] = useState([]);
    const [customerSettingsData, setCustomerSettingsData] = useState([]);
    const [editCustomerOpen, setEditCustomerOpen] = useState(false);
    const [companyName, setCompanyName] = useState(<FontAwesomeIcon className="fa-spin" icon={faSpinner} />);

    const [pageTab, setPageTab] = useState('details');
    const { addToast } = useToasts();
    const pagetab = match.params.tab;

    useEffect(() => {
        if (pagetab) {
            setPageTab(pagetab);

            switch (pagetab) {
                case 'billing':

                    break;
                case 'settings':
                    LoadCustomerSettings();
                    LoadProfileData();

                    break;
                default:
                    LoadProfileData();
            }
        } else {
            setPageTab('details');
            LoadProfileData();
        }

        return () => {
            // cleanup
            setCustomerData({});
            setDataLoading(true);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPageTab, pagetab])

    const LoadProfileData = () => {

        setDataLoading(true);
        CustomerDetails("me")
            .then(response => {
                if (response.status) {
                    setCustomerData(response.data);
                    setDataLoading(false);
                    setCompanyName(response.data.customer_name);
                } else {
                    history.push('/dashboard');
                    console.warn('error:', response)
                    addToast("Error on requesting profile settings data.", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });

    };

    const LoadCustomerSettings = () => {
        setDataSttingsLoading(true);
        CustomerSettings("me")
            .then(response => {
                if (response.status) {
                    setCustomerSettingsData(response.data);
                    setDataSttingsLoading(false);
                } else {
                    history.push('/dashboard');
                    console.warn('error:', response)
                    addToast("Error on requesting profile data.", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history.push("/login");
                } else {
                    addToast("An error occurred. Please check the browser console for more information", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                }
            });
    };

    return (
        <ProtectedLayout {...props} history={history}>
            <div className="row">
                <div className="col-12 mb-1 mb-lg-5">
                    <h5>
                        <FormattedMessage id="profile-details-page-header" />: {companyName}.
                    </h5>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-5">
                    <div className="main-page-container customer-details-container">

                        {/* Profile detail navigation tabs */}
                        <Nav variant="tabs">
                            <NavItem>
                                <NavLink to="/profile" exact className="nav-link">
                                    <FontAwesomeIcon icon={faUser} /> <FormattedMessage id="profile-myinfo" />
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink to="/profile/billing" className="nav-link">
                                    <FontAwesomeIcon icon={faCoins} /> <FormattedMessage id="profile-myinvoices" />
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink to="/profile/settings" className="nav-link">
                                    <FontAwesomeIcon icon={faCog} /> <FormattedMessage id="profile-mysettings" />
                                </NavLink>
                            </NavItem>

                            {/* <NavItem>
                                <NavLink to="/profile/subscription" className="nav-link">
                                    <FormattedMessage id="profile-subscriptions" />
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink to="/profile/users" className="nav-link">
                                    <FormattedMessage id="profile-myusers" />
                                </NavLink>
                            </NavItem> */}
                        </Nav>


                        {pageTab === 'details' ? (
                            <React.Fragment>
                                <div className="customer-views-container">
                                    <div className="btn-toolbar-container">
                                        {/* Header table toolbar */}
                                        <ButtonToolbar>
                                            <ButtonGroup className="ml-auto">
                                                {/* <Button variant="outline-secondary" onClick={() => { setAddCustomerOpen(!addCustomerOpen) }}><FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon> <FormattedMessage id="customers-AddNewCustomer"></FormattedMessage></Button> */}
                                                {/* <Button variant="outline-secondary"><FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon> <FormattedMessage id="customer-AddInvoice" /></Button> */}
                                                <Button variant="outline-success" onClick={() => { setEditCustomerOpen(!editCustomerOpen) }}><FontAwesomeIcon icon={faUserEdit}></FontAwesomeIcon> <FormattedMessage id="profile-Edit" /></Button>
                                                {/* <Button variant="outline-danger" onClick={() => { setDeleteCustomerOpen(!deleteCustomerOpen) }}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> <FormattedMessage id="customer-Delete" /></Button> */}
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </div>

                                    <CustomerDetailView
                                        customerdata={customerData}
                                        dataloading={dataLoading}
                                        LoadCustomerData={LoadProfileData}
                                        history={history}></CustomerDetailView>
                                </div>
                            </React.Fragment>

                        ) : null}

                        {pageTab === 'billing' ? (
                            <React.Fragment>
                                <div className="main-page-container main-page-container--background">
                                    <InvoicesGridview history={history} tableLoading={false} />
                                </div>
                            </React.Fragment>
                        ) : null}

                        {pageTab === 'settings' ? (
                            <React.Fragment>
                                <div className="customer-views-container">
                                    <CustomerSettingsView
                                        dataloading={dataSettingsLoading}
                                        customerdata={customerSettingsData}
                                        refreshData={LoadCustomerSettings}
                                    ></CustomerSettingsView>
                                </div>
                            </React.Fragment>

                        ) : null}
                    </div>


                </div>
            </div>

            {
                !dataLoading & pageTab === 'details' ? (
                    <EditCustomerModal modalIsOpen={editCustomerOpen} modalOpenerHandler={setEditCustomerOpen} customerData={customerData} LoadCustomerData={LoadProfileData} history={history}></EditCustomerModal>
                ) : null
            }
        </ProtectedLayout >
    );
};

export default ProfileDetailPage;