import React, { useState } from "react";
import {
    faUsers,
    faColumns,
    faFileInvoice, faWallet, faUser, faCoins, faCog, faLock, faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Navbar,
    Nav,
    NavDropdown,
} from "react-bootstrap";
import { UserSignout } from '../../services/users';
import { useToasts } from 'react-toast-notifications';
import { FormattedMessage } from "react-intl";
import { NavLink } from 'react-router-dom';
import ChangePasswordModal from '../../cointainers/profile/profile.change.password.modal';
import { ReactComponent as FactuurtjeLogo } from './../../assets/images/factuurtje_online_logo-2_white.svg';

const HeaderBar = ({
    auth,
    history,
    ...props
}) => {

    const [passwordChangeModelOpen, setPasswordChangeModelOpen] = useState(false);
    const { addToast } = useToasts();
    const signout = () => {
        const { username, jwt_token } = auth;
        UserSignout({ username: username, access_token: jwt_token }).then(response => {
            if (response.status) {
                history.push('/login');
                addToast(response.msg, {
                    appearance: 'success',
                    autoDismiss: true,
                });
            }

        });
    }

    return (
        <React.Fragment>

            <Navbar bg="dark" variant="dark" expand="lg" className="headerbar-background">
                <Navbar.Brand href="/">
                    <FactuurtjeLogo className="header-logo" />
                </Navbar.Brand>

                <Navbar.Toggle data-testid="toggleNavbar" />

                <Navbar.Collapse>

                    <Nav className="ml-auto">
                        <NavLink to="/" exact className="nav-link"><FontAwesomeIcon icon={faColumns}></FontAwesomeIcon> <FormattedMessage id="dashboard"></FormattedMessage></NavLink>

                        <NavLink to="/customers" className="nav-link"><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon> <FormattedMessage id="my-customers"></FormattedMessage></NavLink>
                        {/* Mijn klanten <Badge variant="outline-danger">3</Badge> */}
                        <NavLink to="/invoices" className="nav-link"><FontAwesomeIcon icon={faFileInvoice}></FontAwesomeIcon> <FormattedMessage id="invoices"></FormattedMessage></NavLink>
                        <NavDropdown title="Mijn Profiel" alignRight className="dropdown-menu-lg-right">
                            <NavLink to="/profile" className="dropdown-item"><FontAwesomeIcon icon={faWallet} /> € 0,00 </NavLink>
                            <NavLink to="/profile" className="dropdown-item"><FontAwesomeIcon icon={faUser} /> <FormattedMessage id="profile-myinfo" /></NavLink>
                            <NavLink to="/profile/billing" className="dropdown-item"><FontAwesomeIcon icon={faCoins} /> <FormattedMessage id="profile-myinvoices" /></NavLink>
                            <NavLink to="/profile/settings" className="dropdown-item"><FontAwesomeIcon icon={faCog} /> <FormattedMessage id="profile-mysettings" /></NavLink>
                            <NavDropdown.Item className="dropdown-item" onClick={() => setPasswordChangeModelOpen(true)}><FontAwesomeIcon icon={faLock} /> <FormattedMessage id="changepassword" /></NavDropdown.Item>
                            <NavDropdown.Divider></NavDropdown.Divider>
                            <NavDropdown.Item data-testid="button_signout" onClick={e => { signout(e) }}><FontAwesomeIcon icon={faSignOutAlt} /> <FormattedMessage id="logout" /></NavDropdown.Item>

                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>

            <ChangePasswordModal modalIsOpen={passwordChangeModelOpen} modalOpenerHandler={setPasswordChangeModelOpen} />
        </React.Fragment>
    );
};

export default HeaderBar;