/**
 * API Actions
 */

import axios from 'axios';
import { SERVER as Server } from '../config';

// Check if user has been loggedin, setup the Bearer token for all API calls
axios.interceptors.request.use(config => {
    const idToken = JSON.parse(localStorage.getItem('authentication'));
    const data = config;
    if (idToken?.access_token) {
        data.headers.Authorization = `Bearer ${idToken?.access_token}`;
        data.headers.RefreshToken = idToken?.refresh_token;
        return Promise.resolve(data);
    } else {
        return Promise.resolve(data);
    }
});

export const get = (endpoint, config = null) => {
    return (
        axios({
            url: Server + endpoint,
            method: 'get',
            ...config,
        })
            .then(response => response)
            .catch(error => Promise.reject(error.response))
    );
};


export const post = (endpoint, data, config = null) => {
    return (
        axios({
            url: Server + endpoint,
            method: 'post',
            responseType: 'json',
            data,
            ...config,
        })
            .then(response => response)
            .catch(error => Promise.reject(error.response))
    );
};

export const put = (endpoint, data, config = null) => {
    return (
        axios({
            url: Server + endpoint,
            method: 'put',
            responseType: 'json',
            data,
            ...config,
        })
            .then(response => response)
            .catch(error => Promise.reject(error.response))
    );
};

export const remove = (endpoint, data, config = null) => {
    return (
        axios({
            url: Server + endpoint,
            method: 'delete',
            responseType: 'json',
            data,
            ...config,
        })
            .then(response => response)
            .catch(error => Promise.reject(error.response))
    );
};
