import React from "react";
import { ProtectedLayout } from './../../components/layouts';
import {
    faCoins, faUser, faUsers, faEdit, //faFileInvoice,
    faUserPlus, faCog
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { Link, useHistory, } from 'react-router-dom';
import './style.css';


export default function DashBoard({

    ...props
}) {

    const history = useHistory();

    return (
        <ProtectedLayout {...props} history={history}>
            <div className="row">
                <div className="col-12 mb-5">
                    <h5>
                        <FormattedMessage id="your-dashboard" />
                    </h5>
                </div>
            </div>

            <div className="row">

                {/* My customers widget */}
                <div className="col-lg-4 col-xs-12">
                    <Link to="/customers">
                        <div className="dashboard-widget dashboard-widget-a shadow p-3 mb-5">
                            <div className="dashboard-widget--content">
                                <div className="dashboard-widget--content--header"><h4><FormattedMessage id="my-customers"></FormattedMessage></h4></div>
                                <div className="dashboard-widget--content--info">
                                    <div onClick={() => history("/customers/new")}>
                                        <FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon> <FormattedMessage id="customers-AddNewCustomer"></FormattedMessage><br />
                                    </div>
                                    {/* <Link to="/billing/security"><FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon> <FormattedMessage id="customers-OverDue"></FormattedMessage></Link><br /> */}
                                </div>
                            </div>

                            <div className="dashboard-widget--content">
                                <div className="dashboard-widget--icon">
                                    <FontAwesomeIcon icon={faUsers} className="fa-4x"></FontAwesomeIcon>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Invoices widget */}
                <div className="col-lg-4 col-xs-12">
                    <Link to="/invoices">
                        <div className="dashboard-widget dashboard-widget-c shadow p-3 mb-5">
                            <div className="dashboard-widget--content">
                                <div className="dashboard-widget--content--header"><h4><FormattedMessage id="invoices"></FormattedMessage></h4></div>
                                <div className="dashboard-widget--content--info">
                                    {/* <Link to="/billing/new"><FontAwesomeIcon icon={faFileInvoice}></FontAwesomeIcon> <FormattedMessage id="invoice-AddNewInvoice"></FormattedMessage></Link><br /> */}
                                    {/* <Link to="/billing/security"><FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon> <FormattedMessage id="invoice-OverDue"></FormattedMessage></Link><br /> */}
                                </div>
                            </div>

                            <div className="dashboard-widget--content">
                                <div className="dashboard-widget--icon">
                                    <FontAwesomeIcon icon={faCoins} className="fa-4x"></FontAwesomeIcon>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>


                {/* My profile widget */}
                <div className="col-lg-4 col-xs-12">
                    <Link to="/profile">
                        <div className="dashboard-widget dashboard-widget-b shadow p-3 mb-5">
                            <div className="dashboard-widget--content">

                                <div className="dashboard-widget--content--header"><h4> <FormattedMessage id="my-profile"></FormattedMessage></h4></div>

                                <div className="dashboard-widget--content--info">
                                    <div onClick={() => history("/profile")}><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> <FormattedMessage id="profile-edit"></FormattedMessage></div>
                                    <div onClick={() => history("/profile/settings")}><FontAwesomeIcon icon={faCog}></FontAwesomeIcon> <FormattedMessage id="profile-mysettings"></FormattedMessage></div>
                                    {/* <Link to="/profile/security"><FontAwesomeIcon icon={faLock}></FontAwesomeIcon> <FormattedMessage id="profile-change-password"></FormattedMessage></Link><br /> */}
                                    {/* <Link to="/profile/users"><FontAwesomeIcon icon={faUserCog}></FontAwesomeIcon> <FormattedMessage id="profile-users"></FormattedMessage></Link> */}
                                </div>
                            </div>

                            <div className="dashboard-widget--content">
                                <div className="dashboard-widget--icon">
                                    <FontAwesomeIcon icon={faUser} className="fa-4x"></FontAwesomeIcon>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>


            </div>
        </ProtectedLayout>
    );
};
