import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { faTimes, faSpinner, faSave, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useToasts } from 'react-toast-notifications';
import { InvoiceAdd, InvoiceAddDraft } from './../../../services/invoices';
import moment from 'moment';
import { InputMessage } from '../../../components/helpers';

const SaveInvoiceModal = ({
    modalIsOpen,
    modalOpenerHandler,
    invoiceData,
    history,
    ...props
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faCheckSquare);
    const [confirmOption, setConfirmOption] = useState(1);
    const [invoiceDate, setInvoiceDate] = useState(moment(invoiceData.invoice_date).format("DD-MM-YYYY") || moment(new Date()).format("DD-MM-YYYY"));
    const { addToast } = useToasts();

    const onSubmit = () => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);
        AddInvoice();
    };

    const AddInvoice = () => {
        const post_data = {
            ...invoiceData,
            invoice_date: moment.utc(invoiceDate, "DD-MM-YYYY").toISOString(),
        };

        if (confirmOption === 3) {
            InvoiceAddDraft(post_data).then(response => {
                if (response.status) {
                    addToast(response.msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    modalOpenerHandler(false);
                    history.push(`/customer/${invoiceData.customer_id}/billing`);

                    // remove data from store
                    localStorage.removeItem(invoiceData.customer_id);
                } else {
                    addToast(response.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 30000,
                    });
                    modalOpenerHandler(false);
                    setButtonDisabled(false);
                    setButtonIcon(faCheckSquare);
                }
            })
        } else {

            InvoiceAdd({ ...post_data, "save_option": confirmOption })
                .then(response => {
                    if (response.status) {
                        addToast(response.msg, {
                            appearance: 'success',
                            autoDismiss: true
                        });
                        modalOpenerHandler(false);
                        history.push(`/customer/${invoiceData.customer_id}/billing`);

                        // remove data from store
                        localStorage.removeItem(invoiceData.customer_id);
                    } else {
                        addToast(response.msg, {
                            appearance: 'error',
                            autoDismiss: true,
                            autoDismissTimeout: 30000,
                        });
                        modalOpenerHandler(false);
                        setButtonDisabled(false);
                        setButtonIcon(faCheckSquare);
                    }

                })
                .catch(error => {
                    setButtonDisabled(false);
                    setButtonIcon(faCheckSquare);
                });
        };
    };

    return (

        <Modal size="lg" show={modalIsOpen} onHide={() => { modalOpenerHandler(false) }}>

            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faSave}></FontAwesomeIcon> <FormattedMessage id="invoice-SaveOptions"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><FormattedMessage id="invoice-SaveOptions-Header"></FormattedMessage></p>
                <div className="p-3 mb-2">
                    <p>
                        <FormattedMessage id="invoice-SaveOptions-info"></FormattedMessage> <strong>{invoiceData.invoice_data.customer_data.customer_name}</strong>?
                    </p>

                    <p>
                        <label className="custom-control custom-radio">
                            <input
                                name="invoice-option"
                                checked={confirmOption === 1}
                                onChange={() => setConfirmOption(1)}
                                type="radio" className="custom-control-input" />
                            <label className="custom-control-label"></label>
                            <span className="custom-control-description"><FormattedMessage id="invoice-SaveOptions-a"></FormattedMessage></span>
                        </label>
                    </p>

                    <p>
                        <label className="custom-control custom-radio">
                            <input
                                name="invoice-option"
                                checked={confirmOption === 2}
                                onChange={() => setConfirmOption(2)}
                                type="radio" className="custom-control-input" />
                            <label className="custom-control-label"></label>
                            <span className="custom-control-description"><FormattedMessage id="invoice-SaveOptions-b"></FormattedMessage></span>
                        </label>
                    </p>

                    <p>
                        <label className="custom-control custom-radio">
                            <input
                                name="invoice-option"
                                checked={confirmOption === 3}
                                onChange={() => setConfirmOption(3)}
                                type="radio" className="custom-control-input" />
                            <label className="custom-control-label"></label>
                            <span className="custom-control-description"><FormattedMessage id="invoice-SaveOptions-c"></FormattedMessage></span>
                        </label>
                    </p>

                    {/* setValue("invoice_date", ; */}
                    <div className="form-group row">
                        <label className="col-sm-6 col-form-label"><FormattedMessage id="invoice-date"></FormattedMessage>:</label>
                        <div className="col-sm-6">

                            <FormattedMessage id="invoice-date">
                                {NameField =>
                                    <Form.Control
                                        value={invoiceDate}
                                        type="text"
                                        name="invoice_date"
                                        placeholder={NameField}
                                        onChange={e => { setInvoiceDate(e.target.value) }}
                                        isValid={moment(invoiceDate, "DD-MM-YYYY").isValid() ? true : false}
                                        isInvalid={!moment(invoiceDate, "DD-MM-YYYY").isValid() ? true : false} />
                                }
                            </FormattedMessage>
                            <InputMessage className="text-danger" fade={true} message={!moment(invoiceDate, "DD-MM-YYYY").isValid() && "InvalidDate"}></InputMessage>
                        </div>
                    </div>

                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => { modalOpenerHandler(!modalIsOpen) }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> <FormattedMessage id="close"></FormattedMessage></Button>
                <Button variant="outline-success"
                    disabled={!confirmOption && buttonDisabled}
                    onClick={onSubmit}><FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'fa-spin' : ''} /> <FormattedMessage id="invoice-Process"></FormattedMessage></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SaveInvoiceModal;