import React, { useRef, useState, useEffect, } from "react";

import {
    Button, ButtonGroup,
    ButtonToolbar, InputGroup,
    Form, Dropdown, DropdownButton,
    OverlayTrigger, Tooltip
} from "react-bootstrap";
import {
    // faAngleLeft, faAngleRight,
    faArrowAltCircleRight, faMoneyBill, faCheckCircle,
    faTimesCircle, faEdit, faTrashAlt, faSearchDollar, faPaperPlane, faBell
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import Moment from 'react-moment';
import debounce from 'lodash.debounce';
import { FormatEuro, StatusBadge } from './../../components/helpers';

import Spinner from "../../components/Spinner";

const InvoicesGridview = ({
    LoadInvoices,
    handleSearchBar,
    invoicesData = [],
    tableLoading = true,
    history,
    customerID = '',
    setInvoicesStatus,
}) => {

    const [searchInput, setSearchInput] = useState('');
    const [invoiceTotals, setInvoiceTotals] = useState({ "netto": 0, "bruto": 0 });
    const [invoiceFilterStatus, setInvoiceFilterStatus] = useState(<React.Fragment><FormattedMessage id="invoice_status" />: <StatusBadge status="OPEN" /></React.Fragment>);

    useEffect(() => {
        if (tableLoading) {
            setInvoiceTotals({ "netto": 0, "bruto": 0 });
        };
        // eslint-disable-next-line
    }, [tableLoading]);

    useEffect(() => {
        if (invoicesData.length > 0) {
            calculateTotals();
        };
        // eslint-disable-next-line
    }, [invoicesData]);

    // first function for interact on the search input
    const handleSearchInput = (event) => {
        const data = {
            'search_value': event.target.value,
            'search_type': 'invoice',
            'id': customerID,
        };
        setSearchInput(event.target.value);
        handleSearchBarDebounced.current(data);
    };

    // second function, called from the search bar handler
    const handleSearchBarDebounced = useRef(
        debounce(searchInput => {
            // if input field is empty or les the 2 chars, load the total list
            if (searchInput.search_value.length === 0) {
                LoadInvoices();
            } else {
                setSearchInput(searchInput.search_value);
                handleSearchBar(searchInput);
            }
        }, 500)
    );

    const OpenInvoice = (invoice_row) => {
        if (invoice_row.invoice_status === "DRAFT") {
            history.push('/invoice/edit/' + invoice_row.invoice_id);
        } else {
            history.push('/invoice/' + invoice_row.invoice_id);
        }
    };

    const invoiceStatusFilterHandler = (status) => {
        if (status === 'RESET') {
            setInvoicesStatus("OPEN");
            setInvoiceFilterStatus(<React.Fragment><FormattedMessage id="invoice_status" /></React.Fragment>)
        } else {
            setInvoicesStatus(status);
            setInvoiceFilterStatus(<React.Fragment><FormattedMessage id="invoice_status" />: <StatusBadge status={status} /></React.Fragment>)
        }
    };

    const calculateTotals = () => {
        let netto = 0;
        let bruto = 0;

        for (let index = 0; index < invoicesData.length; index++) {
            const element = invoicesData[index];
            bruto = bruto + element.invoice_data.finance_data.total;
            netto = netto + element.invoice_data.finance_data.total_ex;
        };

        setInvoiceTotals({ "netto": netto, "bruto": bruto });
    };

    return (
        <React.Fragment>
            {/* Header table toolbar */}
            {history.location.pathname === "/profile/billing" ? (<ButtonToolbar />) : (
                <div className="btn-toolbar-container">
                    <ButtonToolbar>
                        {/* search field */}
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearchDollar} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormattedMessage id="search-invoicenummer">
                                {NameField =>
                                    <Form.Control
                                        placeholder={NameField}
                                        autoFocus={true}
                                        type="text"
                                        onChange={event => { handleSearchInput(event) }}
                                        value={searchInput}
                                    />
                                }</FormattedMessage>

                            <InputGroup.Append>
                                {customerID.length === 0 && (
                                    <DropdownButton
                                        as={InputGroup.Append}
                                        variant="outline-secondary"
                                        title={invoiceFilterStatus}
                                        id="input-group-dropdown-2">
                                        <Dropdown.Item href="#" onClick={() => invoiceStatusFilterHandler("OPEN")}><FormattedMessage id="OPEN" /></Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => invoiceStatusFilterHandler("PAYED")}><FormattedMessage id="PAYED" /></Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => invoiceStatusFilterHandler("PROCESSING")}><FormattedMessage id="PROCESSING" /></Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => invoiceStatusFilterHandler("DRAFT")}><FormattedMessage id="DRAFT" /></Dropdown.Item>

                                        <Dropdown.Divider />
                                        <Dropdown.Item href="#" onClick={() => invoiceStatusFilterHandler("RESET")}><FormattedMessage id="RESET" /></Dropdown.Item>
                                    </DropdownButton>
                                )}
                                <Button onClick={e => { setSearchInput(''); LoadInvoices() }} disabled={searchInput.length > 0 ? false : true} variant="outline-secondary"><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></Button>
                            </InputGroup.Append>
                        </InputGroup>

                        {customerID.length > 0 && (
                            <ButtonGroup className="ml-auto">
                                <Button variant="outline-secondary" onClick={() => { history.push("/invoice/add/" + customerID) }}><FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon> <FormattedMessage id="customer-AddInvoice" /></Button>
                                {/* <Button variant="outline-secondary">Iets</Button> */}
                                {/* <Button variant="outline-secondary" ><FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon> <FormattedMessage id="customers-AddNewCustomer"></FormattedMessage></Button> */}
                                {/* <Button variant="outline-danger">Delete</Button> */}
                            </ButtonGroup>
                        )}
                    </ButtonToolbar>

                    {!tableLoading && (
                        <div className="" style={{ top: "15px", position: "absolute", right: "25px" }}>
                            Totaal in dit overzicht: <FormatEuro amount={invoiceTotals.netto} /> / <FormatEuro amount={invoiceTotals.bruto} /><small> (in. btw)</small>
                        </div>
                    )}
                </div>
            )}

            {/* Data table */}
            <div className="table-responsive-xl">
                {tableLoading ? (<Spinner label="Loading invoice data..."></Spinner>) : (
                    <table className="table table-hover table-cursor-enabled">
                        <thead className="table-header">
                            <tr>
                                <th>#</th>
                                <th><FormattedMessage id="customer-name"></FormattedMessage></th>
                                <th><FormattedMessage id="invoice-date"></FormattedMessage></th>
                                <th><FormattedMessage id="invoice_status"></FormattedMessage></th>
                                <th><FormattedMessage id="invoice-amount"></FormattedMessage></th>
                                <th className="text-center">
                                    <OverlayTrigger overlay={<Tooltip><FormattedMessage id="invoice-send-by-mail-tt" /></Tooltip>}>
                                        <span className="d-inline-block">
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                        </span>
                                    </OverlayTrigger>
                                </th>
                                <th className="text-center">
                                    <OverlayTrigger overlay={<Tooltip><FormattedMessage id="invoice-reminder-by-mail-tt" /></Tooltip>}>
                                        <span className="d-inline-block">
                                            <FontAwesomeIcon icon={faBell} />
                                        </span>
                                    </OverlayTrigger>
                                </th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoicesData.map((row) => (
                                <tr key={row.invoice_id} onClick={() => OpenInvoice(row)}>
                                    <th>{row.invoice_number}</th>
                                    <td>{row.customer_name}</td>
                                    <td><Moment format="DD-MM-YYYY" date={row.invoice_date} /></td>
                                    <td>
                                        {row.invoice_status === "OPEN" && Date.parse(row.invoice_data.due_date) < new Date() ?
                                            (<StatusBadge status="OVER_DUE" />)
                                            : (<StatusBadge status={row.invoice_status} />
                                            )}
                                    </td>
                                    <td><FormatEuro amount={row.invoice_data?.finance_data?.total}></FormatEuro></td>
                                    <td className="text-center">
                                        {row.invoice_send ? (
                                            <FontAwesomeIcon className="icon-success" icon={faCheckCircle} />
                                        ) : (
                                            <FontAwesomeIcon className="icon-danger" icon={faTimesCircle} />
                                        )}
                                    </td>

                                    <td className="text-center">
                                        {row.invoice_payment_reminder ? (
                                            <FontAwesomeIcon className="icon-success" icon={faCheckCircle} />
                                        ) : (
                                            <FontAwesomeIcon className="icon-danger" icon={faTimesCircle} />
                                        )}
                                    </td>
                                    <td className="text-right">
                                        <Button onClick={() => OpenInvoice(row)} size="sm" variant="outline-secondary">
                                            <FontAwesomeIcon icon={row.invoice_status === "DRAFT" ? faEdit : faArrowAltCircleRight}></FontAwesomeIcon>
                                        </Button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            {invoicesData.length > 0 ? (
                <div className="btn-toolbar-container">
                    <div className="btn-toolbar-container">
                        {/* <ButtonToolbar>
                            <ButtonGroup className="ml-auto" size="sm">
                                <Button variant="outline-secondary"><FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon></Button>
                                <Button variant="outline-secondary"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></Button>
                            </ButtonGroup>
                        </ButtonToolbar> */}
                    </div>

                </div>
            ) : (
                tableLoading ? (null) : (<div className="p-2 text-lowercase"><FormattedMessage id="invoice-no-results-status" /> {invoiceFilterStatus}</div>)
            )}
        </React.Fragment>

    );
};

export default InvoicesGridview;