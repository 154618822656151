import React, { useState, useEffect } from "react";

import {
    Button, ButtonGroup,
    ButtonToolbar
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { useFieldArray } from "react-hook-form";

import Spinner from "../../components/Spinner";
import accounting from 'accounting-js';
import Moment from 'react-moment';
import { FormatEuro, StatusBadge } from './../../components/helpers';

const InvoiceTemplate = ({
    saveInvoice,
    setOpenDeleteDraftInvoice,
    invoiceDataLoading,
    invoiceData,
    customerSettings,
    setInvoiceData,
    register,
    control,
    watch,
    invoiceLines,

}) => {

    const [invoiceFinance, setInvoiceFinance] = useState({});
    const { fields, append, remove } = useFieldArray({ control, name: "invoiceLines" });

    useEffect(() => {
        calculateInvoiceTotal();
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        calculateInvoiceTotal();
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    const calculateRowAmount = (index) => {

        if (invoiceLines[index] && customerSettings) {
            const { quantity, amount, vat_key } = invoiceLines[index] || { quantity: '0', amount: '0', vat_total: '0' };
            const calc = quantity.toString().replace(',', '.') * amount.toString().replace(',', '.');
            const invoice_vat_list = customerSettings.settings_data.invoice_vat_list;

            // get the vat percentage object
            let vat_item = invoice_vat_list.find(vat_item => {
                return vat_item.vat_key === vat_key;
            });

            if (vat_item) {
                invoiceLines[index].vat_total = calc * (vat_item.vat_percentage / 100);
            }

            // return accounting.formatMoney(calc, "€  ", 2, ".", ",");
            return accounting.formatMoney(calc, { symbol: "€", format: "%s %v", decimal: ",", thousand: ".", })
        }
    };

    const calculateInvoiceTotal = () => {
        let total = 0;
        let vat = 0;
        const vat_items = [];

        for (let i = 0; i < invoiceLines.length; i++) {
            const { quantity, amount, vat_total, vat_key } = invoiceLines[i] || { quantity: '0', amount: '0', vat_total: '0' };
            total += amount.toString().replace(',', '.') * quantity.toString().replace(',', '.');
            if (vat_total) {
                let tmp_vat = parseFloat(vat_total.toString().replace(',', '.'));
                vat += tmp_vat;
                var vat_item = vat_items.find(find_vat => { return find_vat.vat_key === vat_key });

                if (vat_item) {
                    vat_item.vat_total += tmp_vat;
                } else {
                    vat_items.push({ vat_key: vat_key, vat_total: tmp_vat });
                }

            } else {
                vat += 0;
            }
        };

        setInvoiceFinance({
            total_ex: accounting.toFixed(total, 2),
            total_vat: accounting.toFixed(vat, 2),
            total: accounting.toFixed(total + vat, 2),
            vat_items: vat_items
        }, createInvoiceData());
    };

    // handler for pressing the tab key
    const pressTabKey = (event) => {
        var enterkey = event.keyCode || event.which;
        // check if enter button has pressed and the form is valid
        if (enterkey === 9) {
            // tab key used
            append({ quantity: '1', description: '', amount: '', vat_total: '' })
        };
    };

    const createInvoiceData = () => {
        return new Promise((resolve, reject) => {
            if (invoiceData) {

                // convert from string to float in invoiceLines
                var invoiceLines_tmp = [] //{ 'quantity': 1, 'description': 'test regel', 'amount': 100 }]
                var i = 0;

                invoiceLines.map(line => {
                    if (line.description.length > 0) {
                        invoiceLines_tmp.push(
                            {
                                'line': i += 1,
                                'quantity': accounting.toFixed(parseFloat(line.quantity.toString().replace(',', '.')), 2),
                                'description': line.description,
                                'amount': accounting.toFixed(parseFloat(line?.amount.toString().replace(',', '.')), 2),
                                'vat_total': accounting.toFixed(parseFloat(line?.vat_total?.toString().replace(',', '.')), 2),
                                'vat_key': line.vat_key,
                            });
                    }
                    return invoiceLines_tmp;
                });

                const post_data = {
                    customer_id: invoiceData.customer_id,
                    invoice_id: invoiceData.invoice_id,
                    invoice_date: invoiceData.invoice_date,
                    invoice_data: {
                        customer_data: { ...invoiceData.invoice_data.customer_data },
                        finance_data: { ...invoiceFinance }
                    },
                    invoice_lines: invoiceLines_tmp
                };

                setInvoiceData(post_data);
                resolve(post_data);

            } else { resolve({}); }
        });
    };

    const saveInvoiceCallback = () => {
        createInvoiceData().then(response => {
            saveInvoice(true);
        });
    };

    return (
        <div className="row">
            <div className="col-12 mb-5">
                {invoiceDataLoading ? (<Spinner></Spinner>) : (
                    <div className="main-page-container main-page-container--background invoice-details-container">

                        {/* customer & invoice info */}
                        <div className="row">

                            {/* customer details */}
                            <div className="col">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"><FormattedMessage id="customers-AddNewcustomer-Details"></FormattedMessage></h5>
                                        {invoiceDataLoading ? (<span><Spinner></Spinner></span>) : (
                                            <React.Fragment>
                                                <FormattedMessage id="customer-debtornumber" />: #{invoiceData.invoice_data.customer_data.customer_debtor_number}<br />

                                                <strong>{invoiceData.invoice_data.customer_data.customer_name}</strong><br />
                                                {invoiceData.invoice_data.customer_data?.business_account && (<span>{invoiceData.invoice_data.customer_data?.contact_name}<br /></span>)}
                                                {invoiceData.invoice_data.customer_data?.customer_street}<br />
                                                {invoiceData.invoice_data.customer_data?.customer_zipcode} {invoiceData.invoice_data.customer_data.customer_city}<br />
                                                {/* {customerData.customer_data.customer_address.customer_country}<br /> */}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>

                            {/* invoice details */}
                            <div className="col">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"><FormattedMessage id="invoice-details" /></h5>
                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td><FormattedMessage id="invoice-number" />:</td>
                                                    <td className="pl-2">
                                                        {invoiceDataLoading && invoiceData?.invoice_number ? (<span>#{invoiceData.invoice_number}</span>) : (
                                                            <StatusBadge status="NUMBER_NOT_SET" />
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td><FormattedMessage id="invoice-date" />:</td>
                                                    <td className="pl-2"><Moment format="DD-MM-YYYY" date={invoiceData.invoice_date || new Date()} /></td>
                                                </tr>

                                                <tr>
                                                    <td><FormattedMessage id="invoice_status" />:</td>
                                                    <td className="pl-2"><StatusBadge status="DRAFT" /></td>
                                                </tr>

                                                <tr>
                                                    <td><FormattedMessage id="invoice-send-to" />:</td>
                                                    <td className="pl-2">{invoiceData.invoice_data.customer_data.customer_data?.contact_email}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* buttons toolsbar */}
                        <div className="row mt-3">
                            <div className="col-12">
                                <ButtonToolbar>
                                    <ButtonGroup className="ml-auto" size="sm">
                                        <Button variant="outline-secondary" onClick={() => append({ quantity: '1', description: '', amount: '', vat_total: '' })}>
                                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> <FormattedMessage id="invoice-add-line" />
                                        </Button>
                                        {/* <Button variant="outline-secondary"><FontAwesomeIcon icon={faEye} /> Preview</Button> */}
                                        <Button variant="outline-success" onClick={() => { saveInvoiceCallback() }}>
                                            <FontAwesomeIcon icon={faSave} /> <FormattedMessage id="invoice-save" />
                                        </Button>
                                        <Button variant="outline-danger" onClick={() => { setOpenDeleteDraftInvoice(true) }}>
                                            <FontAwesomeIcon icon={faTrash} /> <FormattedMessage id="invoice-DeleteDraft" />
                                        </Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </div>
                        </div>

                        {/* table invoice data */}
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="table-responsive-xl add-invoice-table">
                                    <table className="table add-invoice-table--rows">
                                        <thead className="table-header">
                                            <tr>
                                                <th width="10%"><FormattedMessage id="quantity"></FormattedMessage></th>
                                                <th><FormattedMessage id="description"></FormattedMessage></th>
                                                <th width="10%"><FormattedMessage id="amount"></FormattedMessage></th>
                                                <th width="12%" className="text-right"><FormattedMessage id="total"></FormattedMessage></th>
                                                <th width="10%" className="text-center"><FormattedMessage id="vat" /></th>
                                                <th width="5%" className="text-right"><FormattedMessage id="actions" /></th>
                                            </tr>
                                        </thead>
                                        {invoiceDataLoading ? (<tbody><tr><td colSpan="6" className="text-center"><Spinner /></td></tr></tbody>) : (
                                            <tbody>
                                                {fields.map((item, index) => {
                                                    return (
                                                        <tr key={item.id} data-item={JSON.stringify(item)}>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name={`invoiceLines[${index}].quantity`}
                                                                    defaultValue={`${item.quantity}`} // make sure to set up defaultValue
                                                                    ref={register()}
                                                                    onChange={() => { calculateInvoiceTotal() }}
                                                                />
                                                            </td>

                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name={`invoiceLines[${index}].description`}
                                                                    defaultValue={`${item.description}`} // make sure to set up defaultValue
                                                                    ref={register()}
                                                                />
                                                            </td>

                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name={`invoiceLines[${index}].amount`}
                                                                    defaultValue={`${item.amount}`} // make sure to set up defaultValue
                                                                    ref={register()}
                                                                    // onKeyPress={pressTabKey}
                                                                    onKeyDown={pressTabKey}
                                                                    onChange={() => { calculateInvoiceTotal() }}
                                                                />
                                                            </td>
                                                            <td className="text-right align-middle">{calculateRowAmount(index)}</td>
                                                            <td>
                                                                <select
                                                                    className="form-control"
                                                                    name={`invoiceLines[${index}].vat_key`}
                                                                    data-vat={item.vat_key}
                                                                    defaultValue={item.vat_key || customerSettings?.settings_data?.invoice_vat_list.find(find_vat => { return find_vat.vat_default }).vat_key} // make sure to set up defaultValue
                                                                    ref={register()}
                                                                    onChange={() => { calculateInvoiceTotal() }}
                                                                >
                                                                    {customerSettings?.settings_data?.invoice_vat_list.map(vatitem => {
                                                                        return (<option key={vatitem.vat_key} value={vatitem.vat_key}>{vatitem.vat_percentage}%</option>)
                                                                    })}
                                                                </select>
                                                            </td>

                                                            <td className="text-right">
                                                                <Button
                                                                    onClick={() => { remove(index); }}
                                                                    disabled={invoiceLines.length < 2}
                                                                    variant="outline-danger" className="mr-1"><FontAwesomeIcon icon={faTrash} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* total table */}
                        <div className="row">
                            <div className="col"></div>
                            <div className="col-3"></div>
                            <div className="col">
                                <div className="card">
                                    <div className="card-body">
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="invoice-col"><FormattedMessage id="invoice-total-ex-vat" />:</td>
                                                    <td className="pl-2 text-right"><FormatEuro amount={invoiceFinance.total_ex} /></td>
                                                </tr>

                                                <tr>
                                                    <td className="invoice-col"><FormattedMessage id="invoice-total-vat" />:</td>
                                                    <td className="pl-2 text-right"><FormatEuro amount={invoiceFinance.total_vat} /></td>
                                                </tr>

                                                <tr>
                                                    <td className="invoice-col"><FormattedMessage id="invoice-total" />:</td>
                                                    <td className="pl-2 text-right"><FormatEuro amount={invoiceFinance.total} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
};

export default InvoiceTemplate;