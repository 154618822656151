import React from 'react';
import {
    Switch,
    Route,
    BrowserRouter as Router
} from "react-router-dom";

import NotFound from '../components/Notfound';
import LoginPage from '../cointainers/account.login';
import DashboardPage from '../cointainers/dashboard';
import RequestPasswordPage from '../cointainers/account.request.password';
import SignupPage from '../cointainers/account.signup';
import ChangePasswordPage from '../cointainers/account.change.password';
import AccountActivation from '../cointainers/account.activate';
import { CustomersGridview, CustomerDetailPage } from '../cointainers/customers';
import ProfileDetailPage from '../cointainers/profile';
import {
    InvoicePage, InvoiceAddPage, InvoiceEditPage,
    InvoiceViewPage, InvoicePaymentViewPage, InvoicePaymentStatusViewPage
} from '../cointainers/invoices';

const Routes = ({ ...props }) => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={DashboardPage} />
                <Route path="/login" exact component={LoginPage} />
                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/request-password" component={RequestPasswordPage} />
                <Route path="/change-password" component={ChangePasswordPage} />
                <Route path="/register" component={SignupPage} />
                <Route path="/activation/:code" component={AccountActivation} />

                <Route path="/customers" exact component={CustomersGridview} />
                <Route path="/customers/:mode" exact component={CustomersGridview} />
                <Route path="/customer/:customer_id" exact component={CustomerDetailPage} />
                <Route path="/customer/:customer_id/:tab" component={CustomerDetailPage} />

                <Route path="/profile" exact component={ProfileDetailPage} />
                <Route path="/profile/:tab" component={ProfileDetailPage} />

                <Route path="/invoices" exact component={InvoicePage} />
                <Route path="/invoice/add/:customer_id" exact component={InvoiceAddPage} />
                <Route path="/invoice/edit/:invoice_id" exact component={InvoiceEditPage} />
                <Route path="/invoice/:invoice_id" exact component={InvoiceViewPage} />
                <Route path="/invoice/payment/:invoice_id" exact component={InvoicePaymentViewPage} />
                <Route path="/invoice/payment_result/:invoice_id" exact component={InvoicePaymentStatusViewPage} />

                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default Routes;