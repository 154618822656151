import React from "react";
import { Button, Modal } from "react-bootstrap";

import { faTimes, faCheckCircle, faTimesCircle, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

const WarningInvoiceModal = ({
    modalIsOpen,
    modalOpenerHandler,
    invoiceWarningData,
    history,
}) => {

    return (

        <Modal size="lg" show={modalIsOpen} onHide={() => { modalOpenerHandler(!modalIsOpen) }}>

            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon className="text-danger" icon={faTimesCircle}></FontAwesomeIcon> <FormattedMessage id="customer-missing-info-header"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-warning">
                <p><FormattedMessage id="customer-missing-info"></FormattedMessage></p>
                <div className="p-3 mb-2">

                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-center" with="5%">Aanwezig</th>
                                <th>Informatie</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center">
                                    {invoiceWarningData.customer_address?.customer_street ?
                                        (<FontAwesomeIcon className="text-success" icon={faCheckCircle} />) :
                                        (<FontAwesomeIcon className="text-danger" icon={faTimesCircle} />)}
                                </td>
                                <td><FormattedMessage id="streetAndNumber" /></td>
                                <td>{!invoiceWarningData.customer_address?.customer_street && (<div onClick={() => { history.push("/profile"); modalOpenerHandler(false); }} className="btn btn-sm btn-secondary">Oplossen <FontAwesomeIcon icon={faArrowRight} /></div>)}</td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    {invoiceWarningData.customer_address?.customer_zipcode ?
                                        (<FontAwesomeIcon className="text-success" icon={faCheckCircle} />) :
                                        (<FontAwesomeIcon className="text-danger" icon={faTimesCircle} />)}
                                </td>
                                <td><FormattedMessage id="zipcode" /></td>
                                <td>{!invoiceWarningData.customer_address?.customer_zipcode && (<div onClick={() => { history.push("/profile"); modalOpenerHandler(false); }} className="btn btn-sm btn-secondary">Oplossen <FontAwesomeIcon icon={faArrowRight} /></div>)}</td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    {invoiceWarningData.customer_address?.customer_city ?
                                        (<FontAwesomeIcon className="text-success" icon={faCheckCircle} />) :
                                        (<FontAwesomeIcon className="text-danger" icon={faTimesCircle} />)}
                                </td>
                                <td><FormattedMessage id="city" /></td>
                                <td>{!invoiceWarningData.customer_address?.customer_city && (<div onClick={() => { history.push("/profile"); modalOpenerHandler(false); }} className="btn btn-sm btn-secondary">Oplossen <FontAwesomeIcon icon={faArrowRight} /></div>)}</td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    {invoiceWarningData.customer_address?.customer_country ?
                                        (<FontAwesomeIcon className="text-success" icon={faCheckCircle} />) :
                                        (<FontAwesomeIcon className="text-danger" icon={faTimesCircle} />)}
                                </td>
                                <td><FormattedMessage id="country" /></td>
                                <td>{!invoiceWarningData.customer_address?.customer_country && (<div onClick={() => { history.push("/profile"); modalOpenerHandler(false); }} className="btn btn-sm btn-secondary">Oplossen <FontAwesomeIcon icon={faArrowRight} /></div>)}</td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    {invoiceWarningData.customer_reg_number ?
                                        (<FontAwesomeIcon className="text-success" icon={faCheckCircle} />) :
                                        (<FontAwesomeIcon className="text-danger" icon={faTimesCircle} />)}
                                </td>
                                <td><FormattedMessage id="company-registration-number" /></td>
                                <td>{!invoiceWarningData.customer_reg_number && (<div onClick={() => { history.push("/profile"); modalOpenerHandler(false); }} className="btn btn-sm btn-secondary">Oplossen <FontAwesomeIcon icon={faArrowRight} /></div>)}</td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    {invoiceWarningData.customer_vat ?
                                        (<FontAwesomeIcon className="text-success" icon={faCheckCircle} />) :
                                        (<FontAwesomeIcon className="text-danger" icon={faTimesCircle} />)}
                                </td>
                                <td><FormattedMessage id="company-vat-number" /></td>
                                <td>{!invoiceWarningData.customer_vat && (<div onClick={() => { history.push("/profile"); modalOpenerHandler(false); }} className="btn btn-sm btn-secondary">Oplossen <FontAwesomeIcon icon={faArrowRight} /></div>)}</td>
                            </tr>

                            <tr>
                                <td className="text-center">
                                    {invoiceWarningData.payment_account_name ?
                                        (<FontAwesomeIcon className="text-success" icon={faCheckCircle} />) :
                                        (<FontAwesomeIcon className="text-danger" icon={faTimesCircle} />)}
                                </td>
                                <td><FormattedMessage id="customer-setting-bankaccount-name" /></td>
                                <td>{!invoiceWarningData.payment_account_name && (<div onClick={() => { history.push("/profile/settings"); modalOpenerHandler(false); }} className="btn btn-sm btn-secondary">Oplossen <FontAwesomeIcon icon={faArrowRight} /></div>)}</td>
                            </tr>

                            <tr>
                                <td className="text-center">
                                    {invoiceWarningData.payment_account_number ?
                                        (<FontAwesomeIcon className="text-success" icon={faCheckCircle} />) :
                                        (<FontAwesomeIcon className="text-danger" icon={faTimesCircle} />)}
                                </td>
                                <td><FormattedMessage id="customer-setting-bankaccount-no" /></td>
                                <td>{!invoiceWarningData.payment_account_number && (<div onClick={() => { history.push("/profile/settings"); modalOpenerHandler(false); }} className="btn btn-sm btn-secondary">Oplossen <FontAwesomeIcon icon={faArrowRight} /></div>)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => { modalOpenerHandler(!modalIsOpen) }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> <FormattedMessage id="close"></FormattedMessage></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WarningInvoiceModal;