import * as api from '../api';
// import axios from 'axios';

// check_payment_status
export const CheckPaymentStatus = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/payment/check_payment_status', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};

export const PaymentCreate = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/payment/create_payment', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};


export const GetPaymentIssuers = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/payment/issuers', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })

    });
};

export const GetPaymentInvoice = (p_InvoiceID) => {
    return new Promise((resolve, reject) => {
        api
            .get('/payment/invoice/' + p_InvoiceID)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })

    });
};
