import React from 'react';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Spinner = ({
    label = "Loading..."
}) => {
    return (
        <div className="pb-5 pt-5 text-center" data-testid="spinner">
            <div className="col-12 mb-r text-center h-100">
                <div className="spinner-loading">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x"></FontAwesomeIcon>
                    <br></br>
                    {label}
                </div>
            </div>
        </div>
    );
};

export default Spinner;